import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Add, ArrowRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { TablePagination } from "../../../redesign/components/common/TablePagination";
import { fetchTimeTables } from "../../../redux/actions/schedules.js";
import { CreateTimeTable } from "./Modals/createTimeTable.js";
import { TimeTableItem } from "./TimeTableItem";
import useStyles from "./styles";
import LoadingSpinnerComponent from "../../../redesign/components/common/LoadingSpinnerComponent.js";
export const TimeTableComponent = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["schedules"]);
  const isEnglish = language === "en";

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTimeTables());
  }, [dispatch]);

  const timeTables = useSelector((state) => state.schedules.timeTables);
  const timeTablesLoading = useSelector(
    (state) => state.schedules.timeTableLoading,
  );
  console.log(timeTables);
  const tableBody = timeTables
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((timeTable) => {
      console.log(timeTable);
      return <TimeTableItem timeTable={timeTable} />;
    });

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        bgcolor={"white"}
        padding={2}
        borderRadius={15}
        boxShadow={4}
        maxHeight={"70vh"}
      >
        <Grid container justifyContent="flex-end" item xs={12}>
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={() => setOpen(true)}
            endIcon={<Add className={classes.addIcon} />}
          >
            {t("schedules:add")}
          </Button>
        </Grid>
        <Grid
          container
          justifyContent="center"
          item
          xs={12}
          style={{ marginTop: "20px" }}
        >
          <Box
            maxHeight={"60vh"}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            sx={{
              overflow: "auto",
              padding: 10,
              "&::-webkit-scrollbar": {
                width: "8px", // Set the width of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888", // Set the color of the scrollbar thumb
                borderRadius: "4px", // Set the border radius of the scrollbar thumb
                marginRight: "2px",
              },
            }}
          >
            {timeTablesLoading ? (
              <LoadingSpinnerComponent />
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow key={"columnHead"}>
                      <TableCell align={isEnglish ? "left" : "right"}>
                        {t("schedules:name")}
                      </TableCell>
                      <TableCell align={isEnglish ? "left" : "right"}>
                        {t("schedules:checkIn")}
                      </TableCell>
                      <TableCell align={isEnglish ? "left" : "right"}>
                        {t("schedules:checkOut")}
                      </TableCell>
                      <TableCell align={isEnglish ? "left" : "right"}>
                        {t("schedules:duration")}
                      </TableCell>
                      <TableCell align={isEnglish ? "left" : "right"}>
                        {t("schedules:actions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableBody.length === 0 ? null : tableBody}
                  </TableBody>
                </Table>
                {tableBody.length === 0 ? (
                  <div style={{ textAlign: "center", padding: 20 }}>
                    <Typography
                      style={{ fontSize: "20px", fontStyle: "normal" }}
                    >
                      {t("schedules:noRecords")}
                    </Typography>
                  </div>
                ) : null}
                <Grid container justifyContent="center" item xs={12}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    rowsPerPage={rowsPerPage}
                    count={timeTables.length}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Grid>
              </TableContainer>
            )}
          </Box>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={12}>
          <Link to="/shifts">
            <Button
              variant="contained"
              className={classes.addButton}
              endIcon={<ArrowRight className={classes.addIcon} />}
            >
              {t("schedules:createShifts")}
            </Button>
          </Link>
        </Grid>
      </Box>
      <CreateTimeTable open={open} setOpen={setOpen} t={t} />
    </>
  );
};
