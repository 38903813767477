import { Box, TableCell, TableRow } from "@material-ui/core";
import { DateRange, Delete } from "@material-ui/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import DeleteSchedule from "./Modals/deleteScheduleModal";
import useStyles from "./styles";

export const ScheduleItem = ({ employeeSchedule, setSelected, selected }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["schedules"]);
  const isEnglish = language === "en";

  // const [openEdit, setEdit] = useState(false);
  const [openDelete, setDelete] = useState(false);
  const isSelected = selected?.id === employeeSchedule.id;

  const classes = useStyles();

  const handleClick = () => {
    setSelected(employeeSchedule);
  };
  // const handleEdit = () => {
  //   console.log("Edit");
  // };
  // const handleDelete = () => {
  //   console.log("Delete");
  // };
  console.log("*** Schedule employeeSchedule ***", employeeSchedule);
  return (
    <>
      <Box clone whiteSpace="nowrap">
        <TableRow
          key={employeeSchedule.employee?.id}
          onClick={handleClick}
          className={`${classes.tableRow} ${
            isSelected ? classes.selected : ""
          }`}
        >
          <TableCell align={isEnglish ? "left" : "right"}>
            {employeeSchedule.employee?.id}
          </TableCell>
          <TableCell
            align={isEnglish ? "left" : "right"}
            style={{
              whiteSpace: "nowrap",
              borderBottom: "none",
            }}
          >
            {employeeSchedule.employee.fullName}
          </TableCell>
          <TableCell
            align={isEnglish ? "left" : "right"}
            style={{
              whiteSpace: "nowrap",
              borderBottom: "none",
            }}
          >
            {isEnglish
              ? employeeSchedule.employee.department.name
              : employeeSchedule.employee.department.nameAr}
          </TableCell>
          <TableCell align={isEnglish ? "left" : "right"}>
            {isEnglish
              ? employeeSchedule.employee.position.name
              : employeeSchedule.employee.position.nameAr}
          </TableCell>
          <TableCell align={isEnglish ? "left" : "right"}>
            {employeeSchedule.shift.name}
          </TableCell>
          <TableCell align={isEnglish ? "left" : "right"}>
            {employeeSchedule.startDate}
          </TableCell>
          <TableCell align={isEnglish ? "left" : "right"}>
            {employeeSchedule.endDate}
          </TableCell>
          <TableCell align={isEnglish ? "left" : "right"}>
            <Link to={`/preview-schedules/${employeeSchedule.id}`}>
              <DateRange color="primary" style={{ cursor: "pointer" }} />
            </Link>
          </TableCell>
          <TableCell align={isEnglish ? "left" : "right"}>
            {/* <Edit
              onClick={() => {
                setEdit(true);
              }}
              color="primary"
              style={{ cursor: "pointer" }}
            />{" "} */}
            <Delete
              onClick={() => {
                setDelete(true);
              }}
              color="error"
              style={{ cursor: "pointer" }}
            />
          </TableCell>
        </TableRow>
      </Box>
      <DeleteSchedule
        openDelete={openDelete}
        setDelete={setDelete}
        t={t}
        schedule={employeeSchedule}
      />
    </>
  );
};
