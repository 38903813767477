export const normalizeShift = (shift) => {
  console.log("normalize shift===", shift, !shift);
  if (!shift) return null;
  const { id, name, unit, cycle, timetables, shiftStructure } = shift;
  let newStructure = [];
  for (let i = 0; i < cycle; i++) {
    if (unit.toLowerCase() === "week") {
      let week = shiftStructure.slice(i * 7, i * 7 + 7);
      console.log("week===", week);
      let weekStructure = week.map((day) =>
        timetables.find((t) => +t.id === +day),
      );
      newStructure.push(weekStructure);
    } else if (unit.toLowerCase() === "month") {
      let month = shiftStructure.slice(i * 31, i * 31 + 31);
      let monthStructure = month.map((day) =>
        timetables.find((t) => +t.id === +day),
      );
      newStructure.push(monthStructure);
    } else {
      let days = shiftStructure.map((day) =>
        timetables.find((t) => +t.id === +day),
      );
      newStructure = days;
    }
  }
  console.log("newStructure===", {
    id,
    name,
    unit: unit.toLowerCase(),
    cycle,
    timetables: [],
    shiftStructure: newStructure,
  });
  return {
    id,
    name,
    unit: unit.toLowerCase(),
    cycle,
    timetables: [],
    shiftStructure: newStructure,
  };
};
