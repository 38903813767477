import { Button, Grid, Typography } from "@material-ui/core";
import { Cancel, Delete, Save } from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteTimeTable, updateTimeTable } from "../../../../redux/actions";
import { CompanyStructureModal, FormInputField } from "../../../common";
import TimePickerField from "../../../common/TimePickerField";
import useStyles from "../styles";

function calculateDuration(checkIn, checkOut) {
  // Parse time strings into Moment.js objects
  const checkInMoment = moment(checkIn, "HH:mm:ss");
  const checkOutMoment = moment(checkOut, "HH:mm:ss");

  // Calculate duration
  const duration = moment.duration(checkOutMoment.diff(checkInMoment));

  // Get duration components
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  // Format the duration
  const formattedDuration = `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;

  return formattedDuration;
}

const UpdateOrDeleteTimeTable = ({
  openEdit,
  setEdit,
  openDelete,
  setDelete,
  t,
  timeTable,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let [timetable, setTimetable] = useState(timeTable);
  const [error, setError] = useState(false);

  const handleUpdate = () => {
    let errors = {};
    if (timetable.name === "") {
      errors = { ...errors, name: t("schedules:nameRequired") };
    }
    if (timetable.checkIn === null) {
      errors = { ...errors, checkIn: t("schedules:checkInRequired") };
    }
    if (timetable.checkOut === null) {
      errors = { ...errors, checkOut: t("schedules:checkOutRequired") };
    }
    setError(errors);
    console.log("errors", errors);
    console.log("error", error);
    console.log("timeTable", timetable);
    if (
      timetable.name !== "" &&
      timetable.checkIn !== null &&
      timetable.checkOut !== null
    ) {
      setError(false);
      dispatch(updateTimeTable(timetable));
      setEdit(false);
    }
  };
  const handleDelete = () => {
    dispatch(deleteTimeTable({ id: timeTable.id }));
    setDelete(false);
  };
  return (
    <CompanyStructureModal
      open={openEdit || openDelete}
      onClose={() => (openEdit ? setEdit(false) : setDelete(false))}
      fadeIn={openEdit || openDelete}
      title={
        openEdit ? t("schedules:editTimeTable") : t("schedules:deleteTimeTable")
      }
    >
      {openEdit ? (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
          style={{ marginTop: -30, padding: "30px" }}
        >
          <Grid item xs={12}>
            <FormInputField
              label={t("schedules:name")}
              errors={error.name}
              type="text"
              value={timetable.name}
              onChange={(e) =>
                setTimetable({ ...timetable, name: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerField
              label={t("schedules:checkIn")}
              errors={error.checkIn}
              placeholder={t("schedules:checkIn")}
              value={moment(timetable.checkIn, "HH:mm:ss")}
              onChange={(time) =>
                setTimetable({
                  ...timetable,
                  checkIn: moment(time, "HH:mm:ss").format("HH:mm:ss"),
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerField
              label={t("schedules:checkOut")}
              errors={error.checkOut}
              placeholder={t("schedules:checkOut")}
              value={moment(timetable.checkOut, "HH:mm:ss")}
              onChange={(time) =>
                setTimetable({
                  ...timetable,
                  checkOut: moment(time, "HH:mm:ss").format("HH:mm:ss"),
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputField
              label={t("schedules:duration")}
              type="text"
              value={calculateDuration(timetable.checkIn, timetable.checkOut)}
              disabled={true}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            item
            xs={12}
          >
            <Button
              variant="contained"
              className={classes.saveButton}
              endIcon={<Save className={classes.addIcon} />}
              onClick={() => {
                handleUpdate();
              }}
            >
              {t("schedules:save")}
            </Button>

            <Button
              variant="contained"
              className={classes.cancelButton}
              endIcon={<Cancel className={classes.addIcon} />}
              onClick={() => {
                setError(false);
                setEdit(false);
              }}
            >
              {t("schedules:cancel")}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
          style={{ marginTop: -30, padding: "30px" }}
        >
          <Grid item xs={12}>
            <Typography>
              {`${t("deleteTimeTableMessage")} ${timeTable.name} ?`}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            item
            xs={12}
          >
            <Button
              variant="contained"
              className={classes.cancelButton}
              endIcon={<Delete className={classes.addIcon} />}
              onClick={() => {
                handleDelete();
              }}
            >
              {t("schedules:delete")}
            </Button>

            <Button
              variant="contained"
              className={classes.addButton}
              endIcon={<Cancel className={classes.addIcon} />}
              onClick={() => {
                setError(false);
                setDelete(false);
              }}
            >
              {t("schedules:cancel")}
            </Button>
          </Grid>
        </Grid>
      )}
    </CompanyStructureModal>
  );
};

export default UpdateOrDeleteTimeTable;
