import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
//Redux
import { connect, useDispatch } from "react-redux";
import { salaryValidation, validation } from "./validation";

import { Alert } from "@material-ui/lab";
import ContactInformation from "./Forms/ContactInformation";
import IdentificationDocuments from "./Forms/IdentificationDocuments";
import { LoadingButton } from "../../common";
import { PageHeader } from "../../../redesign/components/common";
//Components
import PersonalInformation from "./Forms/PersonalInformation";
import PointOfContact from "./Forms/PointOfContact";
import ReactGA from "react-ga";
import Salary from "./Forms/Salary";
import { Save } from "@material-ui/icons/";
import { checkRequiredFields } from "../../common/utils";
//Actions
import { createEmployee } from "../../../redux/actions";
import { fetchDocumentTypes } from "../../../redux/actions/employees";
import { makeStyles } from "@material-ui/core/styles";
import { paths } from "../../../redesign/utils";
//Router
import { useHistory } from "react-router";
//MaterialUI
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  button: {
    color: "white",
    backgroundColor: "#7cc777",
    "&:hover": {
      backgroundColor: "#7cc777",
    },
  },
}));

const AddEmployee = ({
  createEmployee,
  errors,
  documentTypes,
  addEmployeeLoading,
}) => {
  ReactGA.pageview(window.location.pathname);
  const dispatch = useDispatch();

  const [documents, setDocuments] = useState();

  const [civilIdDocumentType, setCivilIdDocumentType] = useState();
  const [passportDocumentType, setPassportDocumentType] = useState();

  useEffect(() => {
    dispatch(fetchDocumentTypes());
  }, [dispatch]);

  useEffect(() => {
    const civildIdValue = documentTypes?.find(
      (type) => type.name === "Civil ID",
    );

    const passportValue = documentTypes?.find(
      (type) => type.name === "Passport",
    );

    setCivilIdDocumentType(civildIdValue);
    setPassportDocumentType(passportValue);

    setDocuments({
      civilId: {
        referenceNumber: "",
        issueDate: null,
        exp: null,
        documentType: civildIdValue?.id,
      },
      passport: {
        referenceNumber: "",
        issueDate: null,
        exp: null,
        documentType: passportValue?.id,
      },
      files: {
        civilId: null,
        passport: null,
      },
    });
  }, [documentTypes]);

  //Hooks
  const { t } = useTranslation(["common", "employeeAdd", "breadcrumbs"]);
  const history = useHistory();

  const classes = useStyles();
  const theme = useTheme();

  //States
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [feErrors, setFeErrors] = useState({});

  const [personalInfo, setPersonalInfo] = useState({
    fullName: "",
    email: "",
    referenceNumber: "",
    image: null,
    nationality: "KW",
    locations: [],
    departmentId: "",
    positionId: "",
    employmentStatus: "",
    gender: "",
    maritalStatus: "",
    dateOfBirth: null,
    employmentDate: null,
    leaveDate: null,
    schedules: null,
    note: "",
  });

  const [contactInfo, setContactInfo] = useState({
    country: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    cityOrTown: "",
    paciNumber: "",
    stateOrProvince: "",
  });
  const [poc, setPoc] = useState({
    name: "",
    email: "",
    country: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    cityOrTown: "",
    paciNumber: "",
    stateOrProvince: "",
  });
  const [salary, setSalary] = useState({
    bankName: "",
    iban: "",
    baseSalary: null,
    recurringSalaryModifications: [],
  });

  const isContractEmployee = personalInfo.employmentStatus === "CONTRACTED";

  const handleChange =
    (setState) =>
    ({ target: { name, value } }) =>
      setState((state) => ({ ...state, [name]: value }));

  const handleValueChange = (setState) => (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleSubmit = () => {
    const { phoneNumber, ...address } = contactInfo;
    const { bankName, iban, baseSalary, recurringSalaryModifications } = salary;
    const { name, email, phoneNumber: number, ...pocAddress } = poc;
    let normalizedLocations = personalInfo.locations.map((loc) => +loc.value);

    const normalizedPersonalInfo = {
      ...personalInfo,
      leaveDate: isContractEmployee ? personalInfo.leaveDate : null,
      locations: normalizedLocations,
    };

    const data = {
      employee: {
        ...normalizedPersonalInfo,
        nationality: personalInfo.nationality,
        phoneNumber,
        address: {
          ...address,
          country: address.country === "NK" ? null : address.country,
        },
        bankName,
        iban,
        baseSalary,
        poc: {
          name,
          email,
          number,
          address: {
            ...pocAddress,
            country: pocAddress.country === "NK" ? null : pocAddress.country,
          },
        },
      },
      ...documents,
      recurringSalaryModifications: recurringSalaryModifications.map(
        (salaryModification) => {
          const { amount, reason, month: startDate } = salaryModification;
          return { amount, reason, startDate };
        },
      ),
    };

    let salaryValidationErrors = checkRequiredFields(
      salary,
      salaryValidation(t),
    );

    if (!salaryValidationErrors) {
      console.log("====================================");
      console.log(data);
      console.log("====================================");

      createEmployee(data, history);
    } else {
      setFeErrors(salaryValidationErrors);
    }
  };

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const steps = [
    t("employeeAdd:personalInformation"),
    t("employeeAdd:contactInformation"),
    t("employeeAdd:identificationDocuments"),
    t("employeeAdd:pointOfContact"),
    t("employeeAdd:salary"),
  ];

  const getStepForm = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <PersonalInformation
            validation={feErrors}
            state={personalInfo}
            handleChange={handleChange(setPersonalInfo)}
            handleValueChange={handleValueChange(setPersonalInfo)}
            isContractEmployee={isContractEmployee}
          />
        );
      case 1:
        return (
          <ContactInformation
            state={contactInfo}
            handleChange={handleChange(setContactInfo)}
            handleValueChange={handleValueChange(setContactInfo)}
          />
        );
      case 2:
        return (
          <IdentificationDocuments
            state={documents}
            setState={setDocuments}
            civilIdDocumentType={civilIdDocumentType}
            passportDocumentType={passportDocumentType}
          />
        );
      case 3:
        return (
          <PointOfContact
            state={poc}
            handleChange={handleChange(setPoc)}
            handleValueChange={handleValueChange(setPoc)}
          />
        );
      case 4:
        return (
          <Salary
            validation={feErrors}
            state={salary}
            setState={setSalary}
            handleChange={handleChange(setSalary)}
          />
        );
      default:
        break;
    }
  };

  const isOptionalStep = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return false;
      case 1:
        return true;
      case 2:
        return true;
      case 3:
        return true;
      case 4:
        return false;
      default:
        break;
    }
  };

  const hasSkippedStep = (step) => skipped.has(step);

  const handleNext = () => {
    if (activeStep === 0) {
      let validationErrors = checkRequiredFields(
        personalInfo,
        validation(t, isContractEmployee),
      );

      if (!validationErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      setFeErrors(validationErrors);
    } else {
      if (hasSkippedStep(activeStep)) {
        setSkipped((prev) => {
          const newSkipped = new Set(prev.values());
          newSkipped.delete(activeStep);
          return newSkipped;
        });
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const stepsList = steps.map((label, index) => {
    const stepProps = {};
    const labelProps = {};
    if (isOptionalStep(index)) {
      labelProps.optional = (
        <Typography
          key={label}
          variant="subtitle2"
          color="textSecondary"
          align={matchesMD ? "flex-start" : "center"}
        >
          {t("common:optional")}
        </Typography>
      );
    }
    if (hasSkippedStep(index)) stepProps.completed = false;
    return (
      <Step key={label} {...stepProps}>
        <StepLabel {...labelProps}>
          <Typography
            variant="h3"
            color="primary"
            style={{ whiteSpace: "nowrap" }}
          >
            {label}
          </Typography>
        </StepLabel>
      </Step>
    );
  });

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.employees.name}`),
      link: paths.employees.link,
    },
    {
      name: t(`breadcrumbs:${paths.employees.create.name}`),
      isLast: true,
    },
  ];

  return (
    <Grid container justifyContent={matchesMD ? "center" : "flex-start"}>
      <Grid item md={11} xs={12}>
        <PageHeader
          paths={navigationPaths}
          title={t(`breadcrumbs:${paths.employees.create.header}`)}
        />
      </Grid>
      <Grid item md={11} xs={12}>
        <Box clone bgcolor="white" borderRadius={20} padding={5} marginY={5}>
          <Grid container justifyContent="center">
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
              <Stepper
                orientation={matchesMD ? "vertical" : "horizontal"}
                activeStep={activeStep}
              >
                {stepsList}
              </Stepper>
              {Object.keys(errors).map((key, idx) => (
                <Grid key={errors[key] + idx} item md={12} xs={11}>
                  <Alert severity="error">{errors[key]}</Alert>
                </Grid>
              ))}
              <Grid item md={12} xs={9}>
                {getStepForm(activeStep)}
              </Grid>
              <Grid item md={12} xs={9}>
                <Box paddingTop={4}>
                  <Grid
                    container
                    justifyContent={
                      activeStep === steps.length - 1 ? "flex-end" : "center"
                    }
                  >
                    <Grid item md={12} xs={9}>
                      <Button
                        disabled={activeStep === 0 || addEmployeeLoading}
                        onClick={handleBack}
                      >
                        {t("common:back")}
                      </Button>
                      {activeStep === steps.length - 1 ? (
                        <LoadingButton
                          variant="contained"
                          className={classes.button}
                          size="large"
                          onClick={handleSubmit}
                          startIcon={<Save />}
                          label={t("common:enroll")}
                          loading={addEmployeeLoading}
                        />
                      ) : (
                        // <Button
                        //   variant="contained"
                        //   className={classes.button}
                        //   size="large"
                        //   onClick={handleSubmit}
                        //   startIcon={<Save />}
                        //   disabled={addEmployeeLoading}
                        // >
                        //   {t("common:enroll")}
                        // </Button>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={handleNext}
                        >
                          {t("common:next")}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </form>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ errors, employees }) => ({
  errors: errors || {},
  documentTypes: employees.documentTypes,
  addEmployeeLoading: employees.addEmployeeLoading,
});

const mapDispatchToProps = {
  createEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);
