//Material UI
import {Box, Button} from '@material-ui/core'

import {Link} from 'react-router-dom'
import {Notifications} from '@material-ui/icons'
import React from 'react'
//Translation
import {useTranslation} from 'react-i18next'

const Announcements = () => {
  const {t} = useTranslation('common')

  return (
    <Box clone component={Link} to="/announcements" display="flex">
      <Button
        style={{borderRadius: 50}}
        variant="contained"
        color="primary"
        startIcon={<Notifications />}
      >
        {t('announcements')}
      </Button>
    </Box>
  )
}

export default Announcements
