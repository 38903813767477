import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core'

import {makeStyles} from '@material-ui/core/styles'
//Translation
import {useTranslation} from 'react-i18next'

const FormInputField = ({
  label,
  handleChange,
  helperText,
  type,
  ...inputProps
}) => {
  const {
    i18n: {language},
  } = useTranslation()
  const isEnglish = language === 'en'

  const useStyles = makeStyles(
    theme => ({
      error: {
        boxShadow: `0 0 0 0.11rem ${theme.palette.common.red}`,
        borderRadius: theme.spacing(0.5),
      },
      input: {
        color: theme.palette.primary.main,
      },

      formControlRoot: {
        padding: 4,
        '& .MuiFormLabel-root': {
          color: theme.palette.text.secondary,
          fontSize: '1rem',
          fontWeight: 500,
        },
        '& .MuiInputLabel-formControl': {
          left: isEnglish ? 0 : null,
          right: isEnglish ? null : 0,
        },
      },
      margin: {
        margin: theme.spacing(1),
      },
    }),
    {index: 1},
  )

  const classes = useStyles()

  return (
    <FormControl fullWidth className={classes.formControlRoot}>
      <Typography
        style={{
          fontSize: '0.700rem',
          fontWeight: 500,
          fontFamily: 'Helvetica',
          color: '#ABB4C6',
        }}
      >
        {label}
      </Typography>
      <TextField
        InputProps={{className: classes.input}}
        id={inputProps.name}
        type={type}
        classes={{
          error: classes.error,
        }}
      />
      <FormHelperText children={helperText} error={inputProps.error} />
    </FormControl>
  )
}

export default FormInputField
