import {
  TIME_TABLES,
  SHIFTS,
  SCHEDULES,
  SCHEDULE_DETAILS,
  ADD_TIME_TABLE,
  EDIT_TIME_TABLE,
  REMOVE_TIME_TABLE,
  ADD_SHIFT,
  EDIT_SHIFT,
  REMOVE_SHIFT,
  ADD_SCHEDULE,
  REMOVE_SCHEDULE,
  TIME_TABLES_LOADING,
  SHIFTS_LOADING,
  SCHEDULES_LOADING,
  SCHEDULE_DETAILS_LOADING,
} from "../actions/types";

const initialState = {
  timeTableLoading: false,
  shiftLoading: false,
  scheduleLoading: false,
  scheduleDetailsLoading: false,
  timeTables: [],
  shifts: [],
  schedules: [],
  scheduleDetails: null,
};

export const schedulesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TIME_TABLES:
      return {
        ...state,
        timeTables: payload,
        timeTableLoading: false,
      };
    case ADD_TIME_TABLE:
      return {
        ...state,
        timeTables: [...state.timeTables, payload],
      };
    case EDIT_TIME_TABLE:
      return {
        ...state,
        timeTables: state.timeTables.map((timeTable) =>
          timeTable.id === payload.id ? payload : timeTable,
        ),
      };
    case REMOVE_TIME_TABLE:
      return {
        ...state,
        timeTables: state.timeTables.filter(
          (timeTable) => timeTable.id !== payload,
        ),
      };
    case SHIFTS:
      return {
        ...state,
        shifts: payload,
        shiftLoading: false,
      };
    case ADD_SHIFT:
      return {
        ...state,
        shifts: [...state.shifts, payload],
      };
    case EDIT_SHIFT:
      return {
        ...state,
        shifts: state.shifts.map((shift) =>
          shift.id === payload.id ? payload : shift,
        ),
      };
    case REMOVE_SHIFT:
      return {
        ...state,
        shifts: state.shifts.filter((shift) => shift.id !== payload),
      };
    case SCHEDULES:
      return {
        ...state,
        schedules: payload,
        scheduleLoading: false,
      };
    case SCHEDULE_DETAILS:
      return {
        ...state,
        scheduleDetails: payload,
        scheduleDetailsLoading: false,
      };
    case ADD_SCHEDULE:
      return {
        ...state,
        schedules: [...state.schedules, ...payload],
      };
    case REMOVE_SCHEDULE:
      return {
        ...state,
        schedules: state.schedules.filter(
          (schedule) => schedule.id !== payload,
        ),
      };
    case TIME_TABLES_LOADING:
      return {
        ...state,
        timeTableLoading: true,
      };
    case SHIFTS_LOADING:
      return {
        ...state,
        shiftLoading: true,
      };
    case SCHEDULES_LOADING:
      return {
        ...state,
        scheduleLoading: true,
      };
    case SCHEDULE_DETAILS_LOADING:
      return {
        ...state,
        scheduleDetailsLoading: true,
      };
    default:
      return state;
  }
};
