import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import { Box, Button, Grid } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  LoadingSpinnerComponent,
  PageHeader,
} from "../../../redesign/components/common";
import { paths } from "../../../redesign/utils";
import { fetchScheduleDetails } from "../../../redux/actions";
import { CompanyStructureModal, FormInputField } from "../../common";
import useStyles from "./styles";
import "./styles.css";
import { date } from "yup";

export const SchedulePreview = () => {
  const { t } = useTranslation(["breadcrumbs", "common", "schedules"]);

  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [normalizedEvents, setNormalizedEvents] = useState([]);
  const [dayDetails, setDayDetails] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchScheduleDetails({ id: id }));
  }, [dispatch, id]);

  const loading = useSelector(
    (state) => state.schedules.scheduleDetailsLoading,
  );
  const events = useSelector(
    (state) => state.schedules.scheduleDetails,
  )?.details;

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.attendances.name}`),
      link: paths.attendances.link,
    },
    {
      name: t(`breadcrumbs:${paths.attendances.schedules.name}`),
      link: paths.attendances.schedules.link,
    },
    {
      name: t(
        `breadcrumbs:${paths.attendances.schedules.schedulePreview.name}`,
      ),
      isLast: true,
    },
  ];

  const normalizeEvents = (events) => {
    let normalized = [];
    events.forEach((event) => {
      if (!event.checkIn || !event.checkOut) {
        normalized.push({
          date: event.date,
          title: "Day Off",
          color: "#ff0033",
        });
      }

      let startTime = moment(`${event.date}T${event.checkIn}+03:00`);
      let title = event.timeTable;

      normalized.push({
        title,
        date: startTime.format(),
        allDay: false,
      });
    });
    setNormalizedEvents(normalized);
  };

  useEffect(() => {
    if (events) {
      normalizeEvents(events);
    }
  }, [events]);

  const handleEventClick = (info) => {
    const timeTable = info.event.title;
    if (timeTable === "Day Off") {
      return;
    }
    const details = events.find((event) => event.timeTable === timeTable);
    setDayDetails({
      title: timeTable,
      date: moment(details.date).format("DD-MM-YYYY"),
      start: moment(details.checkIn, "HH:mm:ss").format("hh:mm A"),
      end: moment(details.checkOut, "HH:mm:ss").format("hh:mm A"),
    });
    setOpen(true);
  };
  return (
    <Box margin={2}>
      <PageHeader
        title={t(
          `breadcrumbs:${paths.attendances.schedules.schedulePreview.name}`,
        )}
        paths={navigationPaths}
        paddingBottom={5}
      />

      <Box bgcolor={"white"} borderRadius={15} boxShadow={4} padding={3}>
        {loading ? (
          <LoadingSpinnerComponent />
        ) : (
          <Grid
            style={{ display: "flex", flexDirection: "column" }}
            item
            xs={12}
          >
            <Grid item xs={12}>
              {events && (
                <FullCalendar
                  headerToolbar={{
                    start: "dayGridWeek,dayGridMonth", // will normally be on the left. if RTL, will be on the right
                    center: "title",
                    end: "today prev,next", // will normally be on the right. if RTL, will be on the left,
                  }}
                  class
                  plugins={[dayGridPlugin]}
                  initialView="dayGridWeek"
                  height={500}
                  weekends={true}
                  events={normalizedEvents}
                  initialDate={moment(events[0]?.date, "YYYY-MM-DD")
                    .set("days", 1)
                    .toDate()}
                  eventClick={handleEventClick}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      {open && (
        <CompanyStructureModal
          open={open}
          fadeIn={open}
          onClose={() => setOpen(false)}
          title={dayDetails.title}
        >
          <Grid container spacing={2} direction="row" style={{ padding: 15 }}>
            <Grid item xs={12}>
              <FormInputField
                disabled={true}
                label={t("schedules:date")}
                value={dayDetails.date}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputField
                disabled={true}
                label={t("schedules:checkIn")}
                value={dayDetails.start}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputField
                disabled={true}
                label={t("schedules:checkOut")}
                value={dayDetails.end}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Button
                className={classes.cancelButton}
                endIcon={<Close />}
                onClick={() => setOpen(false)}
                style={{ width: "20%" }}
              >
                {t("common:close")}
              </Button>
            </Grid>
          </Grid>
        </CompanyStructureModal>
      )}
    </Box>
  );
};
