import { gql } from "@apollo/client";

//Company Create
export const OWNER_CREATE = gql`
  mutation (
    $fullName: String!
    $email: String!
    $password1: String!
    $password2: String!
    $isCompanyOwner: Boolean!
    $isEmployee: Boolean!
  ) {
    register(
      fullName: $fullName
      email: $email
      password1: $password1
      password2: $password2
      isCompanyOwner: $isCompanyOwner
      isEmployee: $isEmployee
    ) {
      success
      errors
    }
  }
`;

export const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        fullName
        email
        isCompanyOwner
        isEmployee
        verified
        hasDocumentAccess
        hasIndemnityAccess
        hasVacationsAccess
        hasAttendanceAccess
        employee {
          image
        }
        company {
          overtimeEnabled
        }
        profile {
          hasAcceptedTerms
        }
        approverRules {
          id
          departments {
            id
            name
            nameAr
          }
          users {
            employee {
              id
              fullName
            }
          }
          kind
          locations {
            id
            name
            nameAr
          }
          order
        }
      }
      success
      errors
    }
  }
`;

export const UPDATE_PROFILE_TERMS = gql`
  mutation ($hasAcceptedTerms: Boolean!) {
    updateUserProfileMutation(hasAcceptedTerms: $hasAcceptedTerms) {
      user {
        fullName
        email
        isCompanyOwner
        isEmployee

        profile {
          hasAcceptedTerms
        }
      }
    }
  }
`;

// export const CREATE_SCHEDULE = gql`
//   mutation ($schedules: [CompanySchedulesInputType]!) {
//     createOrUpdateCompanySchedules(schedules: $schedules) {
//       companySchedules {
//         id
//         days {
//           id
//           name
//           nameAr
//           abbreviationAr
//           abbreviation
//           number
//           order
//         }
//         shifts {
//           id
//           startTime
//           endTime
//         }
//       }
//     }
//   }
// `;

//Company
export const COMPANY_UPDATE = gql`
  mutation (
    $name: String
    $nameAr: String
    $logo: Upload
    $sectorId: Int
    $cameraCheckinEnabled: Boolean
    $overtimeEnabled: Boolean
    $checkInOutsideLocation: Boolean
    $checkInTimeLimit: Int
    $checkOutTimeLimit: Int
    $checkInDistanceLimit: Float
    $checkInCutoff: Boolean
    $checkOutCutoff: Boolean
    $useAttendance: Boolean
    $autoDeductionFromAttendance: Boolean
    $autoCheckout: Boolean
    $autoCheckoutAfter: Int
    $payrollDay: Int
  ) {
    updateCompanyProfile(
      name: $name
      nameAr: $nameAr
      sectorId: $sectorId
      logo: $logo
      cameraCheckinEnabled: $cameraCheckinEnabled
      overtimeEnabled: $overtimeEnabled
      checkInOutsideLocation: $checkInOutsideLocation
      checkInTimeLimit: $checkInTimeLimit
      checkInDistanceLimit: $checkInDistanceLimit
      checkOutTimeLimit: $checkOutTimeLimit
      checkInCutoff: $checkInCutoff
      checkOutCutoff: $checkOutCutoff
      useAttendance: $useAttendance
      autoDeductionFromAttendance: $autoDeductionFromAttendance
      autoCheckout: $autoCheckout
      autoCheckoutAfter: $autoCheckoutAfter
      payrollDay: $payrollDay
    ) {
      company {
        approved
        checkInOutsideLocation
        checkInCutoff
        checkInTimeLimit
        checkOutTimeLimit
        checkOutCutoff
        useAttendance
        autoDeductionFromAttendance
        autoCheckout
        autoCheckoutAfter
        id
        name
        nameAr
        cameraCheckinEnabled
        overtimeEnabled
        logo
        sector {
          id
        }
        address {
          id
          country {
            code
            name
          }
          address1
          address2
          zipCode
          stateOrProvince
          paciNumber
          postcode
          cityOrTown
        }
        vacation {
          annualLeave
          accumulatedFor
          sickLeave
          casualLeave
          shortLeave
          officialHolidays {
            id
            name
            nameAr
            start
            end
          }
        }
        payrollDay
      }
    }
  }
`;

export const ADDRESS_UPDATE = gql`
  mutation (
    $country: String!
    $address1: String!
    $address2: String!
    $cityOrTown: String!
    $postcode: String!
    $zipCode: String!
    $stateOrProvince: String!
    $paciNumber: String!
  ) {
    updateCompanyAddress(
      country: $country
      address1: $address1
      address2: $address2
      cityOrTown: $cityOrTown
      postcode: $postcode
      zipCode: $zipCode
      stateOrProvince: $stateOrProvince
      paciNumber: $paciNumber
    ) {
      address {
        country {
          name
          code
        }
        address1
        address2
        cityOrTown
        postcode
        zipCode
        stateOrProvince
        paciNumber
      }
    }
  }
`;

export const UPDATE_COMPANY_VACATION = gql`
  mutation (
    $annualLeave: Int
    $accumulatedFor: Int
    $sickLeave: Int
    $casualLeave: Int
    $offsetLeave: Int
    $wholeBalance: Boolean
  ) {
    updateCompanyVacation(
      annualLeave: $annualLeave
      accumulatedFor: $accumulatedFor
      sickLeave: $sickLeave
      casualLeave: $casualLeave
      offsetLeave: $offsetLeave
      wholeBalance: $wholeBalance
    ) {
      companyVacation {
        wholeBalance
        annualLeave
        accumulatedFor
        sickLeave
        casualLeave
        offsetLeave
        officialHolidays {
          id
          name
          nameAr
          start
          end
        }
      }
    }
  }
`;

export const UPDATE_OR_CREATE_OFFICIAL_HOLIDAY = gql`
  mutation (
    $id: Int
    $name: String!
    $nameAr: String!
    $start: Date!
    $end: Date!
  ) {
    updateOrCreateOfficialHoliday(
      id: $id
      name: $name
      nameAr: $nameAr
      start: $start
      end: $end
    ) {
      holiday {
        id
        name
        nameAr
        start
        end
      }
    }
  }
`;

export const DELETE_OFFICIAL_HOLIDAY = gql`
  mutation ($id: Int!) {
    deleteOfficialHoliday(id: $id) {
      status
    }
  }
`;

//Documents

export const DOCUMENT_CREATE = gql`
  mutation ($name: String!, $exp: Date!, $locationId: Int) {
    updateOrCreateDocument(name: $name, exp: $exp, locationId: $locationId) {
      document {
        id
        name
        exp
        location {
          id
          name
          nameAr
        }
        files {
          id
          file
        }
      }
    }
  }
`;

export const DOCUMENT_FILE_CREATE = gql`
  mutation ($file: Upload!, $documentId: Int!) {
    updateOrCreateDocumentFile(file: $file, documentId: $documentId) {
      documentFile {
        id
        file
        document {
          id
        }
      }
    }
  }
`;

export const DOCUMENT_UPDATE = gql`
  mutation ($id: Int!, $name: String!, $exp: Date!, $locationId: Int) {
    updateOrCreateDocument(
      id: $id
      name: $name
      exp: $exp
      locationId: $locationId
    ) {
      document {
        id
        name
        exp
        location {
          id
          name
          nameAr
        }
        files {
          id
          file
        }
      }
    }
  }
`;

export const DOCUMENT_FILE_UPDATE = gql`
  mutation ($file: Upload!, $documentId: Int!, $id: Int!) {
    updateOrCreateDocumentFile(file: $file, id: $id, documentId: $documentId) {
      documentFile {
        id
        file
        document {
          id
        }
      }
    }
  }
`;

export const DOCUMENT_DELETE = gql`
  mutation ($id: Int!) {
    deleteDocument(id: $id) {
      status
    }
  }
`;

export const DOCUMENT_FILE_DELETE = gql`
  mutation ($id: Int!) {
    deleteDocumentFile(id: $id) {
      status
    }
  }
`;

export const EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT = gql`
  mutation (
    $id: Int
    $employeeId: Int
    $exp: Date
    $issueDate: Date
    $name: String!
    $referenceNumber: String
    $typeId: Int!
  ) {
    employeeUpdateOrCreateDocument(
      id: $id
      employeeId: $employeeId
      exp: $exp
      issueDate: $issueDate
      name: $name
      referenceNumber: $referenceNumber
      typeId: $typeId
    ) {
      document {
        employee {
          fullName
          id
        }
        id
        name
        referenceNumber
        issueDate
        exp
        type {
          id
          name
          nameAr
        }
        files {
          id
          file
        }
      }
    }
  }
`;

export const EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT_FILE = gql`
  mutation ($id: Int, $employeeId: Int, $documentId: Int!, $file: Upload) {
    employeeUpdateOrCreateDocumentFile(
      id: $id
      employeeId: $employeeId
      documentId: $documentId
      file: $file
    ) {
      documentFile {
        id
        document {
          id
          employee {
            fullName
            id
          }
        }
        file
      }
    }
  }
`;

export const EMPLOYEE_DOCUMENT_DELETE = gql`
  mutation ($id: Int!, $employeeId: Int!) {
    employeeDeleteDocument(id: $id, employeeId: $employeeId) {
      status
    }
  }
`;

export const EMPLOYEE_DOCUMENT_FILE_DELETE = gql`
  mutation ($id: Int!, $employeeId: Int!) {
    employeeDeleteDocumentFile(id: $id, employeeId: $employeeId) {
      status
    }
  }
`;

export const POC_UPDATE = gql`
  mutation ($name: String!, $email: String!, $number: String!) {
    updatePoc(name: $name, email: $email, number: $number) {
      poc {
        id
        name
        email
        number
        address {
          country {
            name
            code
          }
          address1
          address2
          cityOrTown
          postcode
          zipCode
          stateOrProvince
          paciNumber
        }
      }
    }
  }
`;

export const POC_ADDRESS_UPDATE = gql`
  mutation (
    $pocAddress: Boolean!
    $country: String!
    $address1: String!
    $address2: String!
    $cityOrTown: String!
    $postcode: String!
    $zipCode: String!
    $stateOrProvince: String!
    $paciNumber: String!
  ) {
    updateCompanyAddress(
      pocAddress: $pocAddress
      country: $country
      address1: $address1
      address2: $address2
      cityOrTown: $cityOrTown
      postcode: $postcode
      zipCode: $zipCode
      stateOrProvince: $stateOrProvince
      paciNumber: $paciNumber
    ) {
      address {
        country {
          name
          code
        }
        address1
        address2
        cityOrTown
        postcode
        zipCode
        stateOrProvince
        paciNumber
      }
    }
  }
`;

//Structure

export const CREATE_LOCATION = gql`
  mutation (
    $name: String!
    $nameAr: String!
    $latitude: String
    $longitude: String
  ) {
    updateOrCreateLocation(
      name: $name
      nameAr: $nameAr
      latitude: $latitude
      longitude: $longitude
    ) {
      location {
        id
        name
        nameAr
        longitude
        latitude
      }
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation (
    $locationId: Int!
    $name: String!
    $nameAr: String!
    $latitude: String!
    $longitude: String!
  ) {
    updateOrCreateLocation(
      locationId: $locationId
      name: $name
      nameAr: $nameAr
      latitude: $latitude
      longitude: $longitude
    ) {
      location {
        id
        name
        nameAr
        longitude
        latitude
      }
    }
  }
`;

export const CREATE_DEPARTMENT = gql`
  mutation ($name: String!, $nameAr: String!) {
    updateOrCreateDepartment(name: $name, nameAr: $nameAr) {
      department {
        id
        name
        nameAr
        positions {
          id
          name
          nameAr
        }
      }
    }
  }
`;

export const APPROVER_RULE_DELETE = gql`
  mutation ($approverRuleId: Int!) {
    removeRequestApproverRule(approverRuleId: $approverRuleId) {
      status
    }
  }
`;

export const APPROVER_RULE_UPDATE = gql`
  mutation (
    $approverRuleId: Int!
    $departmentIds: [Int]!
    $employeeIds: [Int]!
    $locationIds: [Int]!
    $order: Int
    $addEmployer: Boolean
  ) {
    updateRequestApproverRule(
      approverRuleId: $approverRuleId
      departmentIds: $departmentIds
      employeeIds: $employeeIds
      locationIds: $locationIds
      order: $order
      addEmployer: $addEmployer
    ) {
      approverRule {
        order
        id
        locations {
          id
          name
          nameAr
        }
        departments {
          id
          name
          nameAr
        }
        users {
          id

          employee {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const CREATE_APPROVER_RULE = gql`
  mutation (
    $departmentIds: [Int]!
    $employeeIds: [Int]!
    $locationIds: [Int]!
    $order: Int
    $addEmployer: Boolean
  ) {
    addRequestApproverRule(
      departmentIds: $departmentIds
      employeeIds: $employeeIds
      locationIds: $locationIds
      order: $order
      addEmployer: $addEmployer
    ) {
      approverRule {
        order
        id
        locations {
          id
          name
          nameAr
        }
        departments {
          id
          name
          nameAr
        }
        users {
          id
          employee {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation ($id: Int!) {
    deleteDepartment(id: $id) {
      status
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation ($departmentId: Int!, $name: String!, $nameAr: String!) {
    updateOrCreateDepartment(
      departmentId: $departmentId
      name: $name
      nameAr: $nameAr
    ) {
      department {
        id
        name
        nameAr
        positions {
          id
          name
          nameAr
          employees {
            fullName
            id
            user {
              fullName
              email
            }
            image
          }
        }
      }
    }
  }
`;

export const CREATE_POSITION = gql`
  mutation ($departmentId: Int!, $name: String!, $nameAr: String!) {
    updateOrCreatePosition(
      departmentId: $departmentId
      name: $name
      nameAr: $nameAr
    ) {
      position {
        id
        name
        nameAr
        department {
          id
          name
          nameAr
        }
        employees {
          fullName
          id
          user {
            fullName
            email
          }
          image
        }
      }
    }
  }
`;

export const DELETE_POSITION = gql`
  mutation ($id: Int!) {
    deletePosition(id: $id) {
      status
    }
  }
`;

export const UPDATE_POSITION = gql`
  mutation (
    $positionId: Int!
    $departmentId: Int!
    $name: String!
    $nameAr: String!
  ) {
    updateOrCreatePosition(
      positionId: $positionId
      departmentId: $departmentId
      name: $name
      nameAr: $nameAr
    ) {
      position {
        id
        name
        nameAr
        department {
          id
          name
          nameAr
        }
        employees {
          fullName
          id
          user {
            fullName
            email
          }
          image
        }
      }
    }
  }
`;

//Deposits
export const SALARY_DEPOSIT = gql`
  mutation (
    $employeeIds: [Int]!
    $month: Date!
    $recurringSalaryModifications: [Int]
  ) {
    depositSalaries(
      employeeIds: $employeeIds
      month: $month
      recurringSalaryModifications: $recurringSalaryModifications
    ) {
      madeDeposit {
        id
        user {
          fullName
          email
        }
        salaryDeposits {
          id
          total
        }
      }
      hadDeposit {
        id
        user {
          fullName
          email
        }
        salaryDeposits {
          id
          total
        }
      }
    }
  }
`;

export const CREATE_SALARY_MODIFICATION = gql`
  mutation (
    $id: Int
    $employeeId: Int!
    $month: Date!
    $reason: String!
    $amount: Float!
    $attachment: Upload
    $removeAttachment: Boolean
  ) {
    updateOrCreateSalaryModification(
      id: $id
      employeeId: $employeeId
      month: $month
      reason: $reason
      amount: $amount
      attachment: $attachment
      removeAttachment: $removeAttachment
    ) {
      salaryModification {
        id
        reason
        month
        amount
        attachment
      }
    }
  }
`;

export const SALARY_MODIFICATION_DELETE = gql`
  mutation ($id: Int!) {
    deleteSalaryModification(id: $id) {
      status
    }
  }
`;

export const UPDATE_SALARY_MODIFICATION = gql`
  mutation (
    $id: Int!
    $employeeId: Int!
    $month: Date!
    $reason: String!
    $amount: Float!
    $attachment: Upload
    $removeAttachment: Boolean
  ) {
    updateOrCreateSalaryModification(
      id: $id
      employeeId: $employeeId
      month: $month
      reason: $reason
      amount: $amount
      attachment: $attachment
      removeAttachment: $removeAttachment
    ) {
      salaryModification {
        id
        reason
        month
        amount
        attachment
      }
    }
  }
`;

export const CREATE_RECURRING_SALARY_MODIFICATION = gql`
  mutation (
    $employeeId: Int!
    $startDate: Date!
    $endDate: Date
    $reason: String!
    $amount: Float!
    $receiveDuringVacation: Boolean
    $attachment: Upload
    $removeAttachment: Boolean
  ) {
    updateOrCreateRecurringSalaryModification(
      employeeId: $employeeId
      startDate: $startDate
      endDate: $endDate
      reason: $reason
      amount: $amount
      receiveDuringVacation: $receiveDuringVacation
      attachment: $attachment
      removeAttachment: $removeAttachment
    ) {
      recurringSalaryModification {
        id
        reason
        startDate
        endDate
        amount
        receiveDuringVacation
        attachment
      }
    }
  }
`;

export const CREATE_RECURRING_SALARY_MODIFICATIONS = gql`
  mutation (
    $employeeId: Int!
    $recurringSalaryModifications: [RecurringSalaryModificationInputType]!
  ) {
    createRecurringSalaryModifications(
      employeeId: $employeeId
      recurringSalaryModifications: $recurringSalaryModifications
    ) {
      recurringSalaryModifications {
        id
      }
    }
  }
`;

export const UPDATE_RECURRING_SALARY_MODIFICATION = gql`
  mutation (
    $id: Int!
    $employeeId: Int!
    $startDate: Date!
    $endDate: Date
    $reason: String!
    $amount: Float!
    $receiveDuringVacation: Boolean
    $attachment: Upload
    $removeAttachment: Boolean
  ) {
    updateOrCreateRecurringSalaryModification(
      id: $id
      employeeId: $employeeId
      startDate: $startDate
      endDate: $endDate
      reason: $reason
      amount: $amount
      receiveDuringVacation: $receiveDuringVacation
      attachment: $attachment
      removeAttachment: $removeAttachment
    ) {
      recurringSalaryModification {
        id
        reason
        startDate
        endDate
        amount
        receiveDuringVacation
        attachment
      }
    }
  }
`;

export const RECURRING_SALARY_MODIFICATION_DELETE = gql`
  mutation ($id: Int!) {
    deleteRecurringSalaryModification(id: $id) {
      status
    }
  }
`;

// Employees
export const CREATE_EMPLOYEE = gql`
  mutation (
    $address: AddressInputType
    $baseSalary: Float!
    $dateOfBirth: Date
    $departmentId: Int!
    $email: String
    $employmentDate: Date
    $leaveDate: Date
    $employmentStatus: EmploymentStatus
    $fullName: String!
    $locationId: Int
    $locations: [Int]
    $gender: Gender
    $image: Upload
    $maritalStatus: MaritalStatus
    $nationality: String
    $phoneNumber: String
    $poc: PointOfContactInputType
    $positionId: Int!
    $referenceNumber: String
    $schedules: EmployeeScheduleInputType
    $bankName: String
    $iban: String
    $note: String
  ) {
    createEmployee(
      address: $address
      baseSalary: $baseSalary
      dateOfBirth: $dateOfBirth
      departmentId: $departmentId
      email: $email
      employmentDate: $employmentDate
      leaveDate: $leaveDate
      employmentStatus: $employmentStatus
      fullName: $fullName
      locationId: $locationId
      locations: $locations
      gender: $gender
      image: $image
      maritalStatus: $maritalStatus
      nationality: $nationality
      phoneNumber: $phoneNumber
      poc: $poc
      positionId: $positionId
      referenceNumber: $referenceNumber
      schedules: $schedules
      bankName: $bankName
      iban: $iban
      note: $note
    ) {
      employee {
        fullName
        id
        referenceNumber
        user {
          fullName
          email
          isEmployee
        }
        phoneNumber
        gender
        image

        department {
          id
          name
          nameAr
        }

        position {
          id
          name
          nameAr
        }

        nationality {
          name
          code
        }

        locations {
          id
          name
          nameAr
          latitude
          longitude
        }
        location {
          id
          name
          nameAr
          latitude
          longitude
        }
        nextSalaryDeposit {
          salaryModifications {
            amount
          }
          recurringSalaryModifications {
            amount
          }
        }

        employmentStatus
        hrAccess
        maritalStatus
        dateOfBirth
        baseSalary
        iban
        bankName
        lastUpdated
        employmentDate
        leaveDate
        note
      }
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation (
    $id: Int!
    $fullName: String
    $email: String
    $nationality: String
    $departmentId: Int
    $positionId: Int
    $gender: Gender
    $locationId: Int
    $locations: [Int]
    $maritalStatus: MaritalStatus
    $phoneNumber: String
    $address: AddressInputType
    $employmentStatus: EmploymentStatus
    $image: Upload
    $baseSalary: Float
    $dateOfBirth: Date
    $poc: PointOfContactInputType
    $employmentDate: Date
    $leaveDate: Date
    $note: String
    $referenceNumber: String
    $archived: Boolean
    $checkInOutsideLocation: Boolean
    $overrideCheckInOutsideLocation: Boolean
    $schedules: [CompanySchedulesInputType]
    $bankName: String
    $iban: String
  ) {
    updateEmployee(
      id: $id
      referenceNumber: $referenceNumber
      fullName: $fullName
      email: $email
      nationality: $nationality
      departmentId: $departmentId
      positionId: $positionId
      gender: $gender
      locationId: $locationId
      locations: $locations
      maritalStatus: $maritalStatus
      phoneNumber: $phoneNumber
      address: $address
      employmentStatus: $employmentStatus
      image: $image
      baseSalary: $baseSalary
      dateOfBirth: $dateOfBirth
      employmentDate: $employmentDate
      leaveDate: $leaveDate
      poc: $poc
      note: $note
      archived: $archived
      checkInOutsideLocation: $checkInOutsideLocation
      overrideCheckInOutsideLocation: $overrideCheckInOutsideLocation
      schedules: $schedules
      bankName: $bankName
      iban: $iban
    ) {
      employee {
        id
        referenceNumber
        fullName
        checkInOutsideLocation
        overrideCheckInOutsideLocation
        user {
          fullName
          email
        }
        phoneNumber
        gender
        image
        lastUpdated
        employmentDate
        leaveDate
        archived
        company {
          id
          logo
          name
          nameAr
        }
        department {
          id
          name
          nameAr
        }
        position {
          id
          name
          nameAr
        }
        locations {
          id
          name
          nameAr
        }
        location {
          id
          name
          nameAr
          latitude
          longitude
        }
        schedules {
          id
          days {
            id
            name
            nameAr
            number
            order
          }
          shifts {
            id
            startTime
            endTime
          }
        }

        nationality {
          name
          code
        }
        employmentStatus
        note
        hrAccess
        maritalStatus
        dateOfBirth
        overtimeCredits {
          id
          date
          amount
          overtimeRequest {
            request {
              status
            }
          }
        }
        address {
          id
          country {
            name
            code
          }
          address1
          address2
          cityOrTown
          postcode
          zipCode
          stateOrProvince
          paciNumber
        }
        poc {
          id
          name
          email
          number
          address {
            id
            country {
              name
              code
            }
            address1
            address2
            cityOrTown
            postcode
            zipCode
            stateOrProvince
            paciNumber
          }
        }
        baseSalary
        iban
        bankName
        documents {
          id
          referenceNumber
          issueDate
          name
          exp
          files {
            id
            file
          }
        }
        leaveBalance {
          annualBalance
          casualBalance
          sickBalance
          leavedebits {
            id
            reason
            amount
            kind
            isCounted
            date
            endDate
            document
          }
          leavecredits {
            id
            reason
            kind
            reason
            amount
            document
          }
        }
        nextSalaryDeposit {
          baseSalary
          salaryModifications {
            id
            reason
            month
            amount
          }
          recurringSalaryModifications {
            id
            reason
            amount
            startDate
            endDate
            receiveDuringVacation
          }
        }
        requests {
          id
          createdOn
          lastModified
          kind
          status
          lastModifiedBy {
            fullName
          }
          leaveRequest {
            reason
            kind
            amount
            date
            endDate
          }
          resignationRequest {
            reason
            date
          }
          updateProfileRequest {
            old
            new
          }
        }
      }
    }
  }
`;

export const UPDATE_LEAVE_REQUEST = gql`
  mutation (
    $id: Int!
    $employeeId: Int
    $date: Date
    $kind: LeaveType
    $status: Status
    $reason: String
    $endDate: Date
    $isCounted: Boolean
    $notes: String
    $isOverflowed: Boolean
    $document: Upload
    $startTime: Time
    $endTime: Time
  ) {
    updateLeaveRequest(
      id: $id
      employeeId: $employeeId
      date: $date
      endDate: $endDate
      kind: $kind
      status: $status
      notes: $notes
      reason: $reason
      isCounted: $isCounted
      isOverflowed: $isOverflowed
      document: $document
      startTime: $startTime
      endTime: $endTime
    ) {
      leaveRequest {
        id
        reason
        amount
        kind
        date
        endDate
        startTime
        endTime
        document
        leaveDebit {
          isCounted
        }
        request {
          id
          status
          notes
        }
      }
    }
  }
`;

export const CREATE_LEAVE_REQUEST = gql`
  mutation (
    $date: Date!
    $kind: LeaveType!
    $reason: String
    $endDate: Date!
    $document: Upload
    $startTime: Time
    $endTime: Time
    $requestSalaryBeforeLeave: Boolean
  ) {
    createLeaveRequest(
      date: $date
      kind: $kind
      reason: $reason
      endDate: $endDate
      document: $document
      startTime: $startTime
      endTime: $endTime
      requestSalaryBeforeLeave: $requestSalaryBeforeLeave
    ) {
      leaveRequest {
        reason
        kind
        amount
        date
        endDate
        startTime
        endTime
        requestSalaryBeforeLeave
        request {
          id
          createdOn
          lastModified
          kind
          status
          notes
          lastModifiedBy {
            fullName
          }
          leaveRequest {
            reason
            kind
            amount
            date
            endDate
            requestSalaryBeforeLeave
            isOverflowed
            document
          }
        }
      }
    }
  }
`;

export const CREATE_OVERTIME_REQUEST = gql`
  mutation ($date: Date!, $amount: Int!) {
    createOvertimeRequest(date: $date, amount: $amount) {
      overtimeRequest {
        id
        request {
          id
          status
          kind
          notes
        }
        date
        amount
      }
    }
  }
`;

export const UPDATE_OVERTIME_REQUEST = gql`
  mutation (
    $id: Int!
    $employeeId: Int
    $date: Date
    $status: Status
    $notes: String
  ) {
    updateOvertimeRequest(
      id: $id
      employeeId: $employeeId
      date: $date
      status: $status
      notes: $notes
    ) {
      overtimeRequest {
        id
        date
        request {
          id
          status
          notes
          kind
        }
      }
    }
  }
`;

export const CREATE_PROFILE_UPDATE_REQUEST = gql`
  mutation (
    $fullName: String
    $phoneNumber: String
    $gender: Gender
    $image: Upload
    $departmentId: Int
    $positionId: Int
    $nationality: String
    $employmentStatus: EmploymentStatus
    $maritalStatus: MaritalStatus
    $dateOfBirth: Date
    $address: OptionalAddressInputType
    $poc: OptionalPointOfContactInputType
  ) {
    createProfileUpdateRequest(
      fullName: $fullName
      phoneNumber: $phoneNumber
      gender: $gender
      image: $image
      departmentId: $departmentId
      positionId: $positionId
      nationality: $nationality
      employmentStatus: $employmentStatus
      maritalStatus: $maritalStatus
      dateOfBirth: $dateOfBirth
      address: $address
      poc: $poc
    ) {
      updateProfileRequest {
        request {
          id
          createdOn
          lastModified
          kind
          status
          notes
          lastModifiedBy {
            fullName
          }
          updateProfileRequest {
            old
            new
          }
        }
      }
    }
  }
`;

export const UPDATE_PROFILE_UPDATE_REQUEST = gql`
  mutation (
    $id: Int!
    $employeeId: Int
    $status: Status
    $fullName: String
    $phoneNumber: String
    $gender: Gender
    $image: Upload
    $departmentId: Int
    $positionId: Int
    $nationality: String
    $employmentStatus: EmploymentStatus
    $maritalStatus: MaritalStatus
    $dateOfBirth: Date
    $address: OptionalAddressInputType
    $poc: OptionalPointOfContactInputType
    $notes: String
  ) {
    updateProfileUpdateRequest(
      id: $id
      employeeId: $employeeId
      status: $status
      fullName: $fullName
      phoneNumber: $phoneNumber
      gender: $gender
      image: $image
      departmentId: $departmentId
      positionId: $positionId
      nationality: $nationality
      employmentStatus: $employmentStatus
      maritalStatus: $maritalStatus
      dateOfBirth: $dateOfBirth
      address: $address
      poc: $poc
      notes: $notes
    ) {
      updateProfileRequest {
        old
        new
        request {
          id
          createdOn
          lastModified
          kind
          status
          notes
          lastModifiedBy {
            fullName
          }
          updateProfileRequest {
            old
            new
          }
          resignationRequest {
            id
          }
          leaveRequest {
            id
          }
        }
      }
    }
  }
`;

export const CREATE_RESIGNATION_REQUEST = gql`
  mutation ($reason: String, $date: Date!) {
    createResignationRequest(reason: $reason, date: $date) {
      resignationRequest {
        request {
          id
          createdOn
          lastModified
          kind
          status
          notes
          lastModifiedBy {
            fullName
          }
          resignationRequest {
            reason
            date
          }
        }
      }
    }
  }
`;

export const UPDATE_RESIGNATION_REQUEST = gql`
  mutation (
    $id: Int!
    $status: Status
    $employeeId: Int
    $reason: String
    $date: Date!
    $notes: String
  ) {
    updateResignationRequest(
      reason: $reason
      date: $date
      id: $id
      status: $status
      employeeId: $employeeId
      notes: $notes
    ) {
      resignationRequest {
        request {
          id
          createdOn
          lastModified
          kind
          status
          notes
          lastModifiedBy {
            fullName
          }
          resignationRequest {
            reason
            date
          }
        }
      }
    }
  }
`;

export const CREATE_GENERAL_REQUEST = gql`
  mutation ($date: Date!, $reason: String, $body: String, $document: Upload) {
    createGeneralRequest(
      date: $date
      reason: $reason
      body: $body
      document: $document
    ) {
      generalRequest {
        request {
          id
          createdOn
          lastModified
          kind
          status
          notes
          lastModifiedBy {
            fullName
          }
          generalRequest {
            reason
            date
            body
            document
          }
        }
      }
    }
  }
`;

export const UPDATE_GENERAL_REQUEST = gql`
  mutation (
    $id: Int!
    $status: Status
    $employeeId: Int
    $reason: String
    $body: String
    $document: Upload
    $notes: String
  ) {
    updateGeneralRequest(
      id: $id
      status: $status
      employeeId: $employeeId
      reason: $reason
      body: $body
      document: $document
      notes: $notes
    ) {
      generalRequest {
        request {
          id
          createdOn
          lastModified
          kind
          status
          notes
          lastModifiedBy {
            fullName
          }
          generalRequest {
            reason
            date
            body
            document
          }
        }
      }
    }
  }
`;

//Passwords
export const CHANGE_PASSWORD = gql`
  mutation (
    $oldPassword: String!
    $newPassword1: String!
    $newPassword2: String!
  ) {
    passwordChange(
      oldPassword: $oldPassword
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      token
      errors
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation ($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

export const RESET_CHANGE_PASSWORD = gql`
  mutation ($token: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordReset(
      token: $token
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
    }
  }
`;

export const VERIFY_ACCOUNT = gql`
  mutation ($email: String!) {
    resendActivationEmail(email: $email) {
      success
      errors
    }
  }
`;

export const CREATE_ANNOUNCEMENT = gql`
  mutation (
    $title: String!
    $titleAr: String!
    $body: String
    $bodyAr: String
    $postDate: DateTime
    $resources: [Int!]
    $departments: [Int!]
    $positions: [Int!]
    $employees: [Int!]
  ) {
    createAnnouncement(
      title: $title
      titleAr: $titleAr
      body: $body
      bodyAr: $bodyAr
      postDate: $postDate
      departments: $departments
      positions: $positions
      employees: $employees
      resources: $resources
    ) {
      announcement {
        title
        titleAr
        body
        bodyAr
        resources {
          id
          name
          nameAr
          description
          descriptionAr
          files {
            file
            description
            descriptionAr
          }
          links {
            link
            description
            descriptionAr
          }
        }
        postDate
        announcementNotifications {
          seen
          employee {
            fullName
            id
            user {
              fullName
            }
            department {
              id
              name
              nameAr
            }
            position {
              id
              name
              nameAr
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ANNOUNCEMENT = gql`
  mutation ($id: Int!, $seen: Boolean!) {
    updateAnnouncementReadReceipt(id: $id, seen: $seen) {
      announcement {
        id
        seen
      }
    }
  }
`;

export const UPDATE_OR_CREATE_LEAVE_CREDIT = gql`
  mutation (
    $id: Int
    $employeeId: Int!
    $reason: String
    $amount: Int!
    $kind: LeaveType!
    $document: Upload
    $date: Date
  ) {
    updateOrCreateLeaveCredit(
      id: $id
      employeeId: $employeeId
      reason: $reason
      amount: $amount
      kind: $kind
      document: $document
      date: $date
    ) {
      leaveCredit {
        id
        reason
        kind
        date
        document
        amount
      }
    }
  }
`;

export const DELETE_LEAVE_CREDIT = gql`
  mutation ($id: Int!) {
    deleteLeaveCredit(id: $id) {
      status
    }
  }
`;

export const UPDATE_OR_CREATE_LEAVE_DEBIT = gql`
  mutation (
    $id: Int
    $employeeId: Int!
    $reason: String
    $date: Date
    $endDate: Date
    $kind: LeaveType!
    $document: Upload
    $amount: Int
    $balanceAdjustment: Boolean
  ) {
    updateOrCreateLeaveDebit(
      id: $id
      employeeId: $employeeId
      reason: $reason
      date: $date
      endDate: $endDate
      kind: $kind
      document: $document
      amount: $amount
      balanceAdjustment: $balanceAdjustment
    ) {
      leaveDebit {
        id
        reason
        kind
        amount
        document
        date
        endDate
        isCounted
        balanceAdjustment
      }
    }
  }
`;

export const DELETE_LEAVE_DEBIT = gql`
  mutation ($id: Int!) {
    deleteLeaveDebit(id: $id) {
      status
    }
  }
`;

export const UPDATE_EMPLOYEE_HR_ACCESS = gql`
  mutation ($employeeId: Int!, $status: Boolean!) {
    updateHrAccess(employeeId: $employeeId, status: $status) {
      result
    }
  }
`;

export const UPDATE_OR_CREATE_OVERTIME_CREDIT = gql`
  mutation ($amount: Int!, $date: Date!, $employeeId: Int!, $id: Int) {
    updateOrCreateOvertimeCredit(
      amount: $amount
      date: $date
      employeeId: $employeeId
      id: $id
    ) {
      overtimeCredit {
        id
        date
        amount
        overtimeCost
      }
    }
  }
`;

// Employee Attendance

export const CREATE_EMPLOYEE_ATTENDANCE = gql`
  mutation (
    $employeeId: Int
    $latitude: String
    $longitude: String
    $locationId: Int
  ) {
    employeeCreateAttendance(
      employeeId: $employeeId
      latitude: $latitude
      longitude: $longitude
      locationId: $locationId
    ) {
      attendance {
        id
        date
        checkIn
        checkOut
        checkInLatitude
        checkOutLatitude
        checkInLongitude
        checkOutLongitude
        location {
          id
          name
          nameAr
          longitude
          latitude
        }
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_ATTENDANCE = gql`
  mutation (
    $id: Int!
    $employeeId: Int
    $latitude: String
    $longitude: String
    $checkIn: DateTime
    $checkOut: DateTime
  ) {
    employeeUpdateAttendance(
      id: $id
      employeeId: $employeeId
      latitude: $latitude
      longitude: $longitude
      checkIn: $checkIn
      checkOut: $checkOut
    ) {
      attendance {
        date
        id
        checkIn
        checkOut
        checkInLongitude
        checkInLatitude
        checkOutLongitude
        checkOutLatitude
        location {
          id
          name
          nameAr
          latitude
          longitude
        }
        employee {
          id
          fullName
          image
          department {
            id
            name
            nameAr
          }
          position {
            id
            name
            nameAr
          }
          location {
            id
            name
            nameAr
            latitude
            longitude
          }
          user {
            fullName
          }
        }
      }
    }
  }
`;

export const CREATE_ANONYMOUS_EMPLOYEE_ATTENDANCE = gql`
  mutation (
    $civilId: String!
    $phoneNumber: String!
    $latitude: String
    $longitude: String
  ) {
    anonymousEmployeeCreateAttendance(
      civilId: $civilId
      phoneNumber: $phoneNumber
      latitude: $latitude
      longitude: $longitude
    ) {
      attendance {
        id
        date
        checkIn
        checkInLatitude
        checkInLongitude
      }
    }
  }
`;

export const UPDATE_ANONYMOUS_EMPLOYEE_ATTENDANCE = gql`
  mutation (
    $civilId: String!
    $phoneNumber: String!
    $latitude: String
    $longitude: String
  ) {
    anonymousEmployeeUpdateAttendance(
      civilId: $civilId
      phoneNumber: $phoneNumber
      latitude: $latitude
      longitude: $longitude
    ) {
      attendance {
        id
        date
        checkIn
        checkOut
        checkInLatitude
        checkInLongitude
        checkOutLatitude
        checkOutLongitude
      }
    }
  }
`;

export const UPDATE_PERMISSION_EMPLOYEE = gql`
  mutation (
    $employeeId: Int!
    $kinds: [UserPermissions]
    $locations: [Int]
    $departments: [Int]
  ) {
    updatePermissions(
      employeeId: $employeeId
      kinds: $kinds
      locations: $locations
      departments: $departments
    ) {
      employee {
        id
        fullName
        user {
          hasDocumentAccess
          hasIndemnityAccess
          hasVacationsAccess
          hasAttendanceAccess
        }
        managedLocations {
          id
          name
          nameAr
          latitude
          longitude
        }
        managedDepartments {
          id
          name
          nameAr
        }
      }
    }
  }
`;

// Attendance Schedule

export const CREATE_TIME_TABLE = gql`
  mutation ($name: String!, $checkIn: Time!, $checkOut: Time!) {
    createTimeTable(name: $name, checkIn: $checkIn, checkOut: $checkOut) {
      timetable {
        id
        name
        checkIn
        checkOut
        duration
      }
    }
  }
`;

export const UPDATE_TIME_TABLE = gql`
  mutation ($id: Int!, $name: String, $checkIn: Time, $checkOut: Time) {
    updateTimeTable(
      id: $id
      name: $name
      checkIn: $checkIn
      checkOut: $checkOut
    ) {
      timetable {
        id
        name
        checkIn
        checkOut
        duration
      }
    }
  }
`;

export const DELETE_TIME_TABLE = gql`
  mutation ($id: Int!) {
    deleteTimeTable(id: $id) {
      status
    }
  }
`;

export const CREATE_SHIFT = gql`
  mutation (
    $name: String!
    $unit: String!
    $cycle: Int!
    $timetables: [Int]!
    $shiftStructure: [Int]!
  ) {
    createShift(
      name: $name
      unit: $unit
      cycle: $cycle
      timetables: $timetables
      shiftStructure: $shiftStructure
    ) {
      shift {
        id
        name
        unit
        cycle
        timetables {
          id
          name
          checkIn
          checkOut
        }
        shiftStructure
      }
    }
  }
`;

export const UPDATE_SHIFT = gql`
  mutation (
    $id: Int!
    $name: String
    $unit: String
    $cycle: Int
    $timetables: [Int]!
    $shiftStructure: [Int]!
  ) {
    updateShift(
      id: $id
      name: $name
      unit: $unit
      cycle: $cycle
      timetables: $timetables
      shiftStructure: $shiftStructure
    ) {
      shift {
        id
        name
        unit
        cycle
        timetables {
          id
          name
          checkIn
          checkOut
        }
        shiftStructure
      }
    }
  }
`;

export const DELETE_SHIFT = gql`
  mutation ($id: Int!) {
    deleteShift(id: $id) {
      status
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation ($schedulesInput: [EmployeeScheduleInputType]!) {
    createEmployeeSchedule(schedulesInput: $schedulesInput) {
      schedules {
        id
        employee {
          id
          fullName
          position {
            id
            name
            nameAr
          }
          department {
            id
            name
            nameAr
          }
        }
        shift {
          id
          name
          timetables {
            id
            name
          }
        }
        startDate
        endDate
      }
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation ($id: Int!, $employeeId: Int!) {
    deleteEmployeeSchedule(id: $id, employeeId: $employeeId) {
      status
    }
  }
`;
