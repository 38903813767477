import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { LoadingSpinnerComponent } from "../../../../redesign/components/common";
import moment from "moment";

export const ScheduleDetailsTable = ({ isEnglish, t }) => {
  let scheduleDetails = useSelector((state) => state.schedules.scheduleDetails);
  let scheduleDetailsLoading = useSelector(
    (state) => state.schedules.scheduleDetailsLoading,
  );
  let schedule = scheduleDetails ? scheduleDetails.schedule : null;
  const tableBody2 = () => {
    if (scheduleDetails) {
      let details = scheduleDetails.details;
      return details.map((detail, index) => {
        return (
          <TableRow key={"scheduleDetailsTable" + index}>
            <TableCell align={isEnglish ? "left" : "right"}>
              {detail.date}
            </TableCell>
            <TableCell align={"center"}>
              {detail.checkIn
                ? moment(detail.checkIn, "HH:mm").format("hh:mm A")
                : "-"}
            </TableCell>
            <TableCell align={"center"}>
              {detail.checkOut
                ? moment(detail.checkOut, "HH:mm").format("hh:mm A")
                : "-"}
            </TableCell>
          </TableRow>
        );
      });
    } else {
      return null;
    }
  };
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      bgcolor={"White"}
      boxShadow={4}
      borderRadius={15}
      width={"25%"}
      maxHeight={"60vh"}
      style={{ marginInlineStart: "10px", padding: "10px" }}
    >
      <div
        style={{
          backgroundColor: "#ddd",
          fontSize: "20px",
          padding: "10px",
          borderRadius: "10px",
          textAlign: "center",
          position: "sticky",
          top: 0,
          zIndex: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <Typography>
            {scheduleDetails
              ? `${schedule?.employee?.fullName}`
              : t("common:details").toUpperCase()}
          </Typography>
        </div>
        <div>
          <Typography>
            {scheduleDetails
              ? `${schedule?.startDate} - ${schedule?.endDate}`
              : null}
          </Typography>
        </div>
      </div>
      {scheduleDetailsLoading ? (
        <LoadingSpinnerComponent />
      ) : (
        <Box
          maxHeight={"60vh"}
          width={"100%"}
          sx={{
            marginTop: "5px",
            overflow: "auto",
            padding: 10,
            "&::-webkit-scrollbar": {
              width: "8px", // Set the width of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888", // Set the color of the scrollbar thumb
              borderRadius: "4px", // Set the border radius of the scrollbar thumb
              marginRight: "2px",
            },
          }}
        >
          {scheduleDetails && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow key={"columnHead"}>
                    <TableCell align={isEnglish ? "left" : "right"}>
                      {t("schedules:date")}
                    </TableCell>
                    <TableCell align={"center"}>
                      {t("schedules:checkIn")}
                    </TableCell>
                    <TableCell align={"center"}>
                      {t("schedules:checkOut")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableBody2()}</TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
    </Box>
  );
};
