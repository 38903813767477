import {Box} from '@material-ui/core'
// Components
import FullWidthImage from './FullWidthImage'
import React from 'react'

const RoundedImage = ({image, maxHeight = '100%', onClick}) => {
  return (
    <Box
      maxHeight={maxHeight}
      mb={3}
      borderRadius="12px"
      onClick={onClick}
      clone
    >
      <FullWidthImage src={image} role="presentation" />
    </Box>
  )
}

export default RoundedImage
