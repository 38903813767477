import { Button, Checkbox, Table, Typography } from "@material-ui/core";
import { CancelOutlined, Save } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CompanyStructureModal,
  FormInputField,
  FormSelectInput,
} from "../../../common";
import useStyles from "../styles";
import { useDispatch } from "react-redux";
import { createShift } from "../../../../redux/actions";
import { Alert } from "@material-ui/lab";

export const CreateShift = ({ open, setOpen, timeTables, week }) => {
  const {
    t,
    // i18n: { language },
  } = useTranslation(["schedules"]);
  // const isEnglish = language === "en";

  const classes = useStyles();

  const [selected, setSelected] = useState(null);
  const [shift, setShift] = useState({
    name: "",
    unit: "day",
    cycle: 1,
    timeTable: [],
    shiftStructure: [null],
  });
  console.log("===== shift =====", shift);
  const [error, setError] = useState(false);

  const handleUnitChange = (e) => {
    shift.unit = e;
    shift.cycle = 1;
    if (e === "week") {
      setShift({
        ...shift,
        shiftStructure: [Array.from({ length: 7 }).map(() => null)],
      });
    } else if (e === "month") {
      setShift({
        ...shift,
        shiftStructure: [Array.from({ length: 31 }).map(() => null)],
      });
    } else {
      setShift({
        ...shift,
        shiftStructure: Array.from({ length: 1 }).map(() => null),
      });
    }
  };

  const handlecycleChange = (cycles) => {
    let unitStructure =
      shift.unit === "day"
        ? null
        : shift.unit === "week"
        ? Array.from({ length: 7 }).map(() => null)
        : Array.from({ length: 31 }).map(() => null);
    let newSturcture = [];

    for (let i = 0; i < cycles; i++) {
      newSturcture.push(unitStructure);
    }

    setShift({ ...shift, cycle: cycles, shiftStructure: newSturcture });
  };

  const handleTimeTableSelection = (timeTable) => {
    if (selected?.id === timeTable.id) {
      setSelected(null);
    } else {
      setSelected(timeTable);
    }
  };

  const handleStructureSet = (cycle, day) => {
    // Create a copy of the current shift structure
    let updatedStructure = JSON.parse(JSON.stringify(shift.shiftStructure));
    console.log("updatedStructure", updatedStructure);

    if (shift.unit === "day") {
      updatedStructure[cycle] = selected;
    } else {
      updatedStructure[cycle][day] = selected;
    }
    console.log("updatedStructure change", updatedStructure);
    // Update the state with the new structure
    setShift({ ...shift, shiftStructure: updatedStructure });
  };
  console.log("selected", selected);
  function getTimeTables(structure) {
    let flatStructure = structure.flat();
    let timeTables = [];
    flatStructure.forEach((timeTable) => {
      if (timeTable) {
        if (!timeTables.includes(timeTable.id)) {
          timeTables.push(timeTable.id);
        }
      }
    });
    return timeTables;
  }

  function resetCreateShift() {
    setShift({
      name: "",
      unit: "day",
      cycle: 1,
      timeTable: [],
      shiftStructure: [null],
    });
    setSelected(null);
    setError(false);
  }
  const dispatch = useDispatch();
  const handleSubmit = () => {
    let errors = {};
    let timeTables = getTimeTables(shift.shiftStructure);
    if (!shift.name) {
      errors = { ...errors, name: t("schedules:nameRequired") };
    }
    if (timeTables.length === 0) {
      errors = { ...errors, timeTable: t("schedules:timeTableRequired") };
    }
    setError(errors);
    console.log("errors", !(shift.name === "" || timeTables.length === 0));
    if (!(shift.name === "" || timeTables.length === 0)) {
      let submitStructure = shift.shiftStructure
        .flat()
        .map((timeTable) => (timeTable ? timeTable.id : null));

      let { name, unit, cycle } = shift;
      let submitShift = {
        name,
        unit: unit.toUpperCase(),
        cycle,
        timetables: timeTables,
        shiftStructure: submitStructure,
      };
      console.log("===== submitShift =====", submitShift);
      dispatch(createShift(submitShift));
      setOpen(false);
      resetCreateShift();
    }
  };
  return (
    <CompanyStructureModal
      open={open}
      onClose={() => setOpen(false)}
      fadeIn={open}
      title={t("schedules:createShift")}
      width="80%"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 15,
          boxShadow: "inset 0px 0px 20px #ccc",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "15px",
          }}
        >
          {error.timeTable && (
            <div style={{ width: "100%" }}>
              <Alert severity="error">{error.timeTable}</Alert>
            </div>
          )}
          <div style={{ width: "100%" }}>
            <FormInputField
              label={t("schedules:name")}
              errors={error.name}
              value={shift.name}
              onChange={(e) => setShift({ ...shift, name: e.target.value })}
              style={{ width: "50%" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "40%",
              maxWidth: "40%",
              height: "50vh",
              marginInline: 5,
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#e6ebf1",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 75,
                borderRight: "1px solid grey",
                borderTop: "1px solid grey",
                borderLeft: "1px solid grey",
                borderBottom: "1px solid #ccc",
              }}
            >
              <Typography variant="h4">{"Time Table"}</Typography>
            </div>
            <div
              style={{
                width: "100%",
                maxHeight: "calc(50vh - 75px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                overflowY: "auto",
                borderRight: "1px solid grey",
                borderBottom: "1px solid grey",
                borderLeft: "1px solid grey",
              }}
            >
              <Table>
                <thead>
                  <tr className={classes.tableRow}>
                    <th
                      align="center"
                      className={`${classes.tableHead} ${classes.fixedHeader} ${classes.firstColumn}`}
                    >
                      <div
                        style={{
                          boxShadow: "5px 0px 9px #ddd",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    <th
                      align="center"
                      className={classes.tableHead}
                      style={{ borderInlineEnd: "1px solid grey" }}
                    >
                      {t("schedules:timeTable")}
                    </th>
                    <th
                      align="center"
                      className={classes.tableHead}
                      key={"timeTablecell day"}
                    >
                      {t("schedules:details")}
                    </th>
                  </tr>
                </thead>

                <tbody className={classes.tableBody}>
                  {timeTables.map((timeTable) => (
                    <tr
                      key={timeTable.id}
                      onClick={() => handleTimeTableSelection(timeTable)}
                      className={`${classes.tableRow} ${
                        timeTable?.id === selected?.id ? classes.selected : ""
                      }`}
                      style={{ height: 50 }}
                    >
                      <td
                        align="center"
                        className={`${classes.firstColumn} ${classes.tableCell}`}
                      >
                        <div
                          style={{
                            display: "flex",
                            boxShadow: "5px 0px 9px #ddd",
                            height: "100%",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox checked={timeTable?.id === selected?.id} />
                        </div>
                      </td>
                      <td
                        align="center"
                        className={classes.tableCellBorders}
                        style={{
                          width: 100,
                        }}
                      >
                        {timeTable.name}
                      </td>
                      <td
                        align="center"
                        className={classes.tableCellBorders}
                        style={{
                          minWidth: 50,
                        }}
                      >
                        {timeTable.checkIn} - {timeTable.checkOut}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div
            style={{
              width: "60%",
              maxWidth: "60%",
              height: "50vh",
              marginInline: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: 5,
                backgroundColor: "#e6ebf1",
                height: 75,
                borderRight: "1px solid grey",
                borderTop: "1px solid grey",
                borderLeft: "1px solid grey",
                borderBottom: "1px solid #ccc",
              }}
            >
              <div style={{ width: "50%" }}>
                <FormSelectInput
                  label={t("schedules:unit")}
                  selectOptions={["day", "week", "month"].map((unit) => ({
                    value: unit,
                    label: t(`schedules:${unit}`),
                  }))}
                  value={shift.unit}
                  onChange={(e) => {
                    handleUnitChange(e);
                  }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <FormInputField
                  label={t("schedules:cycle")}
                  type="number"
                  value={shift.cycle}
                  inputProps={{
                    min: 1,
                    max: Number(
                      `${
                        shift.unit === "week"
                          ? 52
                          : shift.unit === "month"
                          ? 12
                          : 365
                      }`,
                    ),
                  }}
                  onChange={(e) => handlecycleChange(Number(e.target.value))}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                maxHeight: "calc(50vh - 75px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                overflowY: "auto",
                borderRight: "1px solid grey",
                borderBottom: "1px solid grey",
                borderLeft: "1px solid grey",
              }}
            >
              <div>
                <Table>
                  <thead>
                    <tr className={classes.tableRow}>
                      <th
                        className={`${classes.tableHead} ${classes.fixedHeader} ${classes.firstColumn}`}
                      >
                        <div
                          style={{
                            boxShadow: "5px 0px 9px #ddd",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                      </th>
                      {shift.unit === "week" ? (
                        week.map((day, index) => (
                          <th
                            align="center"
                            className={classes.tableHead}
                            key={"week" + index}
                            style={{
                              borderInlineEnd:
                                day === "Sun" ? "hidden" : "1px solid grey",
                              minWidth: 150,
                            }}
                          >
                            {day}
                          </th>
                        ))
                      ) : shift.unit === "day" ? (
                        <th
                          align="center"
                          className={classes.tableHead}
                          key={"timeTablecell day"}
                        >
                          {t("schedules:timeTable")}
                        </th>
                      ) : (
                        shift.shiftStructure[0].map((_, index) => (
                          <th
                            align="center"
                            className={classes.tableHead}
                            key={"month" + index}
                            style={{
                              borderInlineEnd:
                                index === 30 ? "hidden" : "1px solid grey",
                              minWidth: 150,
                            }}
                          >
                            {index + 1}
                          </th>
                        ))
                      )}
                    </tr>
                  </thead>

                  <tbody className={classes.tableBody}>
                    {Array.from({ length: shift.cycle }).map((_, index) => (
                      <tr style={{ height: 50 }} key={index + "rows"}>
                        <td
                          className={`${classes.firstColumn} ${classes.tableCell}`}
                          align="center"
                        >
                          <div
                            style={{
                              display: "flex",
                              boxShadow: "5px 0px 9px #ddd",
                              height: "100%",
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {index + 1}
                          </div>
                        </td>
                        {shift.unit === "day" ? (
                          <td
                            align="center"
                            className={classes.tableCellBorders}
                            style={{ borderInlineEnd: "hidden" }}
                            key={index + 3 + "timetablecell"}
                            onClick={() => {
                              handleStructureSet(index);
                            }}
                          >
                            {shift.shiftStructure[index]
                              ? shift.shiftStructure[index].name
                              : ""}
                          </td>
                        ) : (
                          shift.shiftStructure[index].map((timeTable, idx) => (
                            <td
                              align="center"
                              className={classes.tableCellBorders}
                              key={index + idx + "timetablecell"}
                              style={{
                                borderInlineEnd:
                                  shift.unit === "week"
                                    ? idx === 6
                                      ? "hidden"
                                      : "1px solid #ccc"
                                    : idx === 30
                                    ? "hidden"
                                    : "1px solid #ccc",
                              }}
                              onClick={() => {
                                handleStructureSet(index, idx);
                              }}
                            >
                              {timeTable ? timeTable.name : ""}
                            </td>
                          ))
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={() => handleSubmit()}
            endIcon={<Save />}
          >
            {t("schedules:save")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(false);
              resetCreateShift();
            }}
            style={{ marginInline: 30 }}
            className={classes.cancelButton}
            endIcon={<CancelOutlined />}
          >
            {t("schedules:cancel")}
          </Button>
        </div>
      </div>
    </CompanyStructureModal>
  );
};
