import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(
  (theme) => ({
    addButton: {
      backgroundColor: theme.palette.common.lightBlue,
      color: "white",
      maxHeight: "60px",

      "&:hover": {
        backgroundColor: theme.palette.common.green,
        color: "white",
      },
    },
    saveButton: {
      backgroundColor: theme.palette.common.blue,
      color: "white",

      "&:hover": {
        backgroundColor: theme.palette.common.green,
        color: "white",
      },
    },
    cancelButton: {
      backgroundColor: theme.palette.common.lightBlue,
      color: "white",
      boxShadow: "none",

      "&:hover": {
        backgroundColor: theme.palette.common.red,
        color: "white",
      },
    },
    addIcon: {
      "& .MuiSvgIcon-root": {
        transform: "scaleX(-1)",
      },
    },
    tableRow: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    },
    selected: {
      backgroundColor: "#e0e0e0",
    },
    tableHead: {
      top: 0,
      position: "sticky",
      backgroundColor: "#e6ebf1",
      color: theme.palette.common.staffiiBlue,
      height: 50,
      minWidth: 50,
      zIndex: 20,
      borderBottom: "1px solid grey",
    },
    fixedHeader: {
      zIndex: 50,
    },
    firstColumn: {
      left: 0,
      position: "sticky",
      backgroundColor: "#e6ebf1",
      boxShadow: "10px 0px 5px #f0f0f0",
      borderInlineEnd: "1px solid grey",
    },
    tableCell: {
      height: 50,
      minWidth: 50,
      width: 50,
    },
    tableBody: {
      backgroundColor: "#F6F8FA",
    },
    tableCellBorders: {
      borderInlineEnd: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
    },
    "@global": {
      ".fc-button-primary": {
        backgroundColor: "#4CAF50", // Green
        color: "white",
        "&:hover": {
          backgroundColor: "#45a049", // Darker Green
        },
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
