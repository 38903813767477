import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Add, ArrowRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { TablePagination } from "../../../redesign/components/common/TablePagination";
import { fetchShifts, fetchTimeTables } from "../../../redux/actions";
import { DetailsTable } from "./DetailsTable/detailsTable";
import { CreateShift } from "./Modals/createShiftModal";
import { ShiftItem } from "./ShiftItem";
import useStyles from "./styles";
import { LoadingSpinnerComponent } from "../../../redesign/components/common";
export const ShiftsComponent = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["schedules"]);
  const isEnglish = language === "en";

  const classes = useStyles();
  const dispatch = useDispatch();
  const matchesLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState(null);
  let week = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  useEffect(() => {
    dispatch(fetchTimeTables());
    dispatch(fetchShifts());
  }, [dispatch]);

  let timeTables = useSelector((state) => state.schedules.timeTables);

  let shifts = useSelector((state) => state.schedules.shifts);
  const shiftsLoading = useSelector((state) => state.schedules.shiftLoading);

  const tableBody = shifts
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((shift) => {
      return (
        <ShiftItem
          shift={shift}
          setSelected={setSelected}
          selected={selected}
          week={week}
          timeTables={timeTables}
        />
      );
    });

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div style={{ display: "flex" }}>
      <Box
        bgcolor={"white"}
        padding={2}
        borderRadius={15}
        boxShadow={4}
        height={"100%"}
        maxHeight={"70vh"}
        width={matchesLg ? "73%" : "100%"}
      >
        <Grid container direction="column" item xs={12}>
          {/* Grid at the top */}
          <Grid container justifyContent="flex-end" item xs={12}>
            <Button
              variant="contained"
              className={classes.addButton}
              onClick={() => setOpen(true)}
              endIcon={<Add className={classes.addIcon} />}
            >
              {t("schedules:add")}
            </Button>
          </Grid>
          {/* Grid in the middle (TableContainer) */}
          <Grid container direction="column" item xs={12}>
            <Box
              maxHeight={"60vh"}
              width={"100%"}
              sx={{
                overflow: "auto",
                padding: 10,
                marginY: 1,
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "4px",
                  marginRight: "2px",
                },
              }}
            >
              {shiftsLoading ? (
                <LoadingSpinnerComponent />
              ) : (
                <TableContainer style={{ width: "100%" }}>
                  <Table>
                    <TableHead>
                      <TableRow key={"columnHead"}>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:name")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:timeTable")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:unit")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:cycle")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{tableBody}</TableBody>
                  </Table>
                  {shifts.length === 0 && (
                    <div style={{ textAlign: "center", padding: 20 }}>
                      <Typography
                        style={{ fontSize: "20px", fontStyle: "normal" }}
                      >
                        {t("schedules:noRecords")}
                      </Typography>
                    </div>
                  )}
                  <Grid container justifyContent="center" item xs={12}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={3}
                      rowsPerPage={rowsPerPage}
                      count={shifts.length}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Grid>
                </TableContainer>
              )}
            </Box>
          </Grid>

          {/* Grid at the bottom */}
          <Grid container justifyContent="flex-end" item xs={12}>
            <Link to="/assign-schedules">
              <Button
                variant="contained"
                className={classes.addButton}
                endIcon={<ArrowRight className={classes.addIcon} />}
              >
                {t("schedules:assignSchedules")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>

      {matchesLg && (
        <DetailsTable
          shift={selected}
          week={week}
          isEnglish={isEnglish}
          t={t}
        />
      )}
      <CreateShift
        open={open}
        setOpen={setOpen}
        week={week}
        timeTables={timeTables}
      />
    </div>
  );
};
