// import EmployeeProfile from "./components/pages/EmployeeProfile";

import { CssBaseline } from "@material-ui/core";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
//Redux
import { connect } from "react-redux";
//Router
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import NewEmployee from "./components/pages/AddEmployee";
import Attendance from "./components/pages/Attendance";
import CompanyCreate from "./components/pages/CompanyCreate";
import CompanyProfile from "./components/pages/CompanyProfile";
import Deposits from "./components/pages/Deposits";
import Documents from "./components/pages/Documents";
import EmployeeAttendance from "./components/pages/EmployeeDashboard/EmployeeAttendance";
import EmployeeDashboardProfile from "./components/pages/EmployeeDashboard/EmployeeDashboardProfile";
import EmployeeHome from "./components/pages/EmployeeDashboard/EmployeeHome";
import EmployeeOvertime from "./components/pages/EmployeeDashboard/EmployeeOvertime";
import EmployeeSalary from "./components/pages/EmployeeDashboard/EmployeeSalary";
import EmployeeVacation from "./components/pages/EmployeeDashboard/EmployeeVacation";
import Notifications from "./components/pages/EmployeeDashboard/Notifications";
import Notification from "./components/pages/EmployeeDashboard/Notifications/Notification";
import EmployeeRequests from "./components/pages/EmployeeDashboard/EmployeeRequests";
import EditEmployee from "./components/pages/EmployeeProfile/EditEmployee";
// import Employees from "./components/pages/Employees";
import { ForgotPassword } from "./components/pages/ForgotPassword";
//Pages
import Home from "./components/pages/Home";
import HypotheticalIndemnities from "./components/pages/HypotheticalIndemnities";
import Login from "./components/pages/Login";
import NuAttendance from "./components/pages/Nu-Attendance";
import Payroll from "./components/pages/Payroll";
import Register from "./components/pages/Register";
import Request from "./components/pages/Request";
import Requests from "./components/pages/Requests";
import { ResetPassword } from "./components/pages/ResetPassword";
import Resource from "./components/pages/Resource";
import CompanyEdit from "./redesign/components/Employer/Company/CompanyEdit";
import Dashboard from "./redesign/layouts/Dashboard/index";
import AccountSettings from "./redesign/pages/AccountSettings";
import {
  AccessAndPermissions,
  Announcements,
  CompanyDocuments,
  CompanySettings,
  EmployeeProfile,
  Employees,
  File,
  LocationsAndDepartments,
  OfficialHolidays,
  Resources,
} from "./redesign/pages/Employer";
import { updateTerms } from "./redux/actions";
import TermsAndPrivacy from "./TermsAndPrivacy";
import moment from "moment";

import "moment/locale/ar";
import "moment/locale/en-au";
import Upload from "./redesign/components/Employer/Upload";
import TimeTable from "./components/pages/TimeTable";
import Shifts from "./components/pages/Shifts";
import Schedules from "./components/pages/Schedules";
import { SchedulePreview } from "./components/pages/Schedules/schedulePreview";

const App = ({ user, employeeProfile, company, updateTerms }) => {
  const {
    i18n: { language },
  } = useTranslation("home");
  moment.locale(language === "ar" ? "ar" : "en-au");

  ReactGA.initialize("UA-202881321-1");

  let hasEmployeeProfileAccess = null;

  if (user || employeeProfile) {
    if (user && user?.isCompanyOwner) hasEmployeeProfileAccess = true;

    if (employeeProfile) {
      hasEmployeeProfileAccess =
        employeeProfile.user.hasAttendanceAccess ||
        employeeProfile.user.hasDocumentAccess |
          employeeProfile.user.hasIndemnityAccess ||
        employeeProfile.user.hasVacationsAccess ||
        employeeProfile.hrAccess
          ? true
          : false;
    }
  }

  useEffect(() => {
    if (language === "ar") {
      document.body.style.direction = "rtl";
    } else {
      document.body.style.direction = "ltr";
    }
  }, [language]);

  return (
    <>
      <Helmet>
        <title>Staffii</title>
      </Helmet>
      <div dir={language === "en" ? "ltr" : "rtl"}>
        <CssBaseline />

        {user && !user.profile?.hasAcceptedTerms && <TermsAndPrivacy />}
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/forgotPassword">
              <ForgotPassword />
            </Route>
            <Route path="/resetPassword/:token">
              <ResetPassword />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/nu-attendance">
              <NuAttendance />
            </Route>
            <Dashboard user={user} employeeProfile={employeeProfile}>
              {user &&
                (user.isCompanyOwner ||
                  (user.isEmployee &&
                    employeeProfile &&
                    (employeeProfile.hrAccess ||
                      employeeProfile.user.hasVacationsAccess))) && (
                  <>
                    <Route exact path="/requests/:type?">
                      <Requests />
                    </Route>
                  </>
                )}

              {user &&
                (user.isCompanyOwner ||
                  (user.isEmployee &&
                    employeeProfile &&
                    employeeProfile.user.hasDocumentAccess)) && (
                  <>
                    <Route exact path="/documents">
                      <Documents />
                    </Route>
                  </>
                )}

              {user &&
                (user.isCompanyOwner ||
                  (user.isEmployee &&
                    employeeProfile &&
                    employeeProfile.user.hasAttendanceAccess)) && (
                  <>
                    <Route exact path="/attendance">
                      <Attendance />
                    </Route>
                  </>
                )}

              {user && user.isCompanyOwner && (
                <>
                  <Route exact path="/company/create">
                    <CompanyCreate />
                  </Route>

                  <Route exact path="/announcements">
                    <Announcements />
                  </Route>

                  <Route exact path="/resource/:resourceId">
                    <Resource />
                  </Route>

                  <Route exact path="/company/profile">
                    <CompanyProfile />
                  </Route>

                  <Route exact path="/deposits">
                    <Deposits />
                  </Route>

                  <Route path="/hypotheticalIndemnities">
                    <HypotheticalIndemnities />
                  </Route>

                  <Route path="/payroll">
                    <Payroll />
                  </Route>

                  <Route exact path="/add/employee">
                    <NewEmployee />
                  </Route>

                  <Route exact path="/editEmployee/:employeeId">
                    <EditEmployee />
                  </Route>

                  <Route exact path="/request/:requestId">
                    <Request />
                  </Route>

                  <Route exact path="/company/file">
                    <File />
                  </Route>
                  <Route exact path="/company/edit">
                    <CompanyEdit />
                  </Route>

                  <Route exact path="/company/locationsAndDepartments">
                    <LocationsAndDepartments />
                  </Route>

                  <Route exact path="/company/documents">
                    <CompanyDocuments />
                  </Route>

                  <Route exact path="/company/settings">
                    <CompanySettings />
                  </Route>

                  <Route exact path="/company/holidays">
                    <OfficialHolidays />
                  </Route>

                  <Route exact path="/company/resources">
                    <Resources />
                  </Route>

                  <Route exact path="/company/userAccessAndPermissions">
                    <AccessAndPermissions />
                  </Route>

                  <Route exact path="/settings">
                    <AccountSettings />
                  </Route>

                  <Route exact path="/">
                    <Home />
                  </Route>
                </>
              )}

              {((user && user.isCompanyOwner) ||
                (employeeProfile && employeeProfile.hrAccess)) && (
                <>
                  <Route exact path="/upload">
                    <Upload />
                  </Route>
                  <Route exact path="/timeTable">
                    <TimeTable />
                  </Route>
                  <Route exact path="/shifts">
                    <Shifts />
                  </Route>
                  <Route exact path="/assign-schedules">
                    <Schedules />
                  </Route>
                  <Route exact path="/preview-schedules/:id?">
                    <SchedulePreview />
                  </Route>
                </>
              )}

              {hasEmployeeProfileAccess && (
                <>
                  <Route path="/employeesList/:locationId?/:departmentId?/:positionId?">
                    <Employees />
                  </Route>
                </>
              )}
              {user && user.isEmployee && (
                <>
                  <Route exact path="/notification/:announcementId">
                    <Notification />
                  </Route>
                  <Route exact path="/notifications">
                    <Notifications />
                  </Route>
                  <Route exact path="/employee/vacation">
                    <EmployeeVacation />
                  </Route>

                  <Route exact path="/employee/attendance">
                    <EmployeeAttendance />
                  </Route>
                  <Route exact path="/employee/salary">
                    <EmployeeSalary />
                  </Route>
                  <Route exact path="/employee/profile">
                    <EmployeeDashboardProfile />
                  </Route>
                  <Route exact path="/request/:requestId">
                    <Request />
                  </Route>
                  <Route exact path="/">
                    <EmployeeHome />
                  </Route>
                  <Route exact path="/employee/employeeRequests">
                    <EmployeeRequests />
                  </Route>
                </>
              )}

              {hasEmployeeProfileAccess && (
                <>
                  <Route exact path="/employees/:employeeId">
                    <EmployeeProfile />
                  </Route>
                </>
              )}

              {employeeProfile?.company?.overtimeEnabled && (
                <Route exact path="/employee/overtime">
                  <EmployeeOvertime />
                </Route>
              )}
              {!user && <Redirect to="/login" />}
              {/* <Redirect to="/" /> */}
            </Dashboard>
          </Switch>
        </Router>
      </div>
    </>
  );
};
//redeploy

const mapStateToProps = ({ errors, auth, company, employee }) => ({
  company: company.profile,
  user: auth.user,
  employeeProfile: employee.profile,
  errors,
});

const mapDispatchToProps = {
  updateTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
