import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { normalizeShift } from "../utils";
import moment from "moment";

export const DetailsTable = ({ shift, isEnglish, t, week }) => {
  const selected = shift ? normalizeShift(shift) : null;
  console.log("=== selected ===", selected);
  const tableBody2 = () => {
    if (selected) {
      for (let i = 0; i < selected.cycle; i++) {
        if (selected.unit === "week") {
          return selected.shiftStructure.map((weeks) => {
            return weeks.map((timeTable, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#6b8ba0",
                      color: "white",
                      borderRadius: "3px",
                    }}
                  >
                    {week[index]}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#F6F8FA" }}
                  >
                    {timeTable
                      ? `${moment(timeTable?.checkIn, "HH:mm:ss").format(
                          "hh:mm A",
                        )} - ${moment(timeTable?.checkOut, "HH:mm:ss").format(
                          "hh:mm A",
                        )}`
                      : "Off"}
                  </TableCell>
                </TableRow>
              );
            });
          });
        } else if (selected.unit === "day") {
          return selected.shiftStructure.map((timeTable, index) => {
            return (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#6b8ba0",
                    color: "white",
                    borderRadius: "3px",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: "#F6F8FA" }}
                >
                  {timeTable
                    ? `${moment(timeTable?.checkIn, "HH:mm:ss").format(
                        "hh:mm A",
                      )} - ${moment(timeTable?.checkOut, "HH:mm:ss").format(
                        "hh:mm A",
                      )}`
                    : "Off"}
                </TableCell>
              </TableRow>
            );
          });
        } else {
          return selected.shiftStructure.map((month) => {
            return month.map((timeTable, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#6b8ba0",
                      color: "white",
                      borderRadius: "3px",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#F6F8FA" }}
                  >
                    {timeTable
                      ? `${moment(timeTable?.checkIn, "HH:mm:ss").format(
                          "hh:mm A",
                        )} - ${moment(timeTable?.checkOut, "HH:mm:ss").format(
                          "hh:mm A",
                        )}`
                      : "Off"}
                  </TableCell>
                </TableRow>
              );
            });
          });
        }
      }
    } else {
      return null;
    }
  };
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      bgcolor={"White"}
      boxShadow={4}
      borderRadius={15}
      width={"25%"}
      height={selected ? "100%" : "400px"}
      style={{ marginInlineStart: "10px", padding: "10px" }}
    >
      <Typography
        style={{
          backgroundColor: "#ddd",
          fontSize: "20px",
          padding: "10px",
          borderRadius: "10px",
          textAlign: "center",
          position: "sticky",
          top: 0,
          zIndex: 10,
        }}
        align={isEnglish ? "left" : "right"}
      >
        {selected?.name
          ? selected.name.toUpperCase()
          : t("schedules:details").toUpperCase()}
      </Typography>
      <Box
        maxHeight={"60vh"}
        width={"100%"}
        sx={{
          marginTop: "5px",
          overflow: "auto",
          padding: 10,
          "&::-webkit-scrollbar": {
            width: "8px", // Set the width of the scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888", // Set the color of the scrollbar thumb
            borderRadius: "4px", // Set the border radius of the scrollbar thumb
            marginRight: "2px",
          },
        }}
      >
        <TableContainer>
          <Table>
            <TableBody>{tableBody2()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
