export const paths = {
  dashboard: {
    header: "overview",
    name: "dashboard",
    link: "/",
    account: {
      name: "settings",
      link: "/settings",
    },
  },
  employees: {
    header: "employees",
    name: "employees",
    link: "/employeesList",
    detail: {
      link: "/employees",
    },
    create: {
      header: "addEmployee",
      name: "create",
      link: "add/employee",
    },
    edit: {
      name: "edit",
      link: "/editEmployee",
    },
  },
  upload: {
    header: "upload",
    name: "upload",
    link: "/upload",
  },
  company: {
    name: "company",
    profile: {
      header: "companyProfile",
      name: "companyProfile",
      link: "/company/file",
      edit: {
        header: "companyEdit",
        name: "edit",
        link: "/company/edit",
      },
    },
    locationsAndDepartments: {
      header: "locationsAndDepartments",
      name: "locationsAndDepartments",
      link: "/company/locationsAndDepartments",
    },
    documents: {
      header: "companyDocuments",
      name: "companyDocuments",
      link: "/company/documents",
    },
    settings: {
      header: "companySettings",
      name: "settings",
      link: "/company/settings",
    },
    holidays: {
      header: "officialHolidays",
      name: "officialHolidays",
      link: "/company/holidays",
    },
    resources: {
      header: "resources",
      name: "resources",
      link: "/company/resources",
    },
    userAccessAndPermissions: {
      header: "userAccessAndPermissions",
      name: "userAccessAndPermissions",
      link: "/company/userAccessAndPermissions",
    },
    requestApprovers: {
      header: "requestApprovers",
      name: "requestApprovers",
      link: "/company/requestApprovers",
    },
  },
  payroll: {
    header: "payroll",
    name: "payroll",
    link: "/payroll",
  },
  deposits: {
    header: "deposits",
    name: "depositSalary",
    link: "/deposits",
  },
  hypotheticalIndemnities: {
    header: "hypotheticalIndemnities",
    name: "hypotheticalIndemnities",
    link: "/hypotheticalIndemnities",
  },
  attendances: {
    header: "attendances",
    name: "attendances",
    link: "/attendance",
    timeTable: {
      header: "timeTable",
      name: "timeTable",
      link: "/timeTable",
    },
    shifts: {
      header: "shifts",
      name: "shifts",
      link: "/shifts",
    },
    schedules: {
      header: "schedules",
      name: "schedules",
      link: "/assign-schedules",
      schedulePreview: {
        header: "schedulePreview",
        name: "schedulePreview",
      },
    },
  },
  requests: {
    header: "requests",
    name: "requests",
    link: "/requests",
    detail: {
      name: "request",
      link: "/request",
    },
  },
  documents: {
    header: "documents",
    name: "documents",
    link: "/documents",
  },
  companyProfile: {
    header: "companyProfile",
    name: "companyProfile",
    link: "/company/profile",
  },
  announcements: {
    header: "announcements",
    name: "announcements",
    link: "/announcements",
  },
};
