import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinnerComponent } from "../../../redesign/components/common";
import { TablePagination } from "../../../redesign/components/common/TablePagination";
import {
  fetchDepartments,
  fetchScheduleDetails,
  fetchSchedules,
  fetchShifts,
} from "../../../redux/actions";
import { ScheduleDetailsTable } from "./DetailsTable/ScheduleDetailsTable";
import { CreateSchedule } from "./Modals/createScheduleModal";
import { ScheduleItem } from "./ScheduleItem";
import useStyles from "./styles";

export const SchedulesComponent = ({ id = null, handleSchedulesChange }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["schedules"]);
  const isEnglish = language === "en";

  const classes = useStyles();
  const dispatch = useDispatch();
  const matchesLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);

  // let week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let shifts = useSelector((state) => state.schedules.shifts);
  let schedules = useSelector((state) => state.schedules.schedules);

  const schedulesLoading = useSelector(
    (state) => state.schedules.scheduleLoading,
  );
  useEffect(() => {
    dispatch(fetchSchedules());
    dispatch(fetchShifts());
    dispatch(fetchDepartments());
    if (selected) {
      dispatch(fetchScheduleDetails({ id: selected.id }));
    }
    if (id) {
      schedules.filter((schedule) => schedule.employee?.id === id);
    }
  }, [dispatch, selected, setSelected, schedules, id]);

  const tableBody = schedules
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((schedule) => {
      return (
        <ScheduleItem
          employeeSchedule={schedule}
          setSelected={setSelected}
          selected={selected}
        />
      );
    });

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Box
          bgcolor={"white"}
          padding={2}
          borderRadius={15}
          boxShadow={4}
          height={"100%"}
          maxHeight={"70vh"}
          width={matchesLg ? "73%" : "100%"}
        >
          {/* Grid at the top */}
          <Grid container justifyContent="flex-end" item xs={12}>
            <Button
              variant="contained"
              className={classes.addButton}
              onClick={() => setOpen(true)}
              endIcon={<Add className={classes.addIcon} />}
            >
              {t("schedules:add")}
            </Button>
          </Grid>
          {/* Grid in the middle (TableContainer) */}
          <Grid container justifyContent="flex-end" item xs={12}>
            <Box
              maxHeight={"60vh"}
              width={"100%"}
              sx={{
                marginY: 1,
                overflow: "auto",
                padding: 10,
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "4px",
                  marginRight: "2px",
                },
              }}
            >
              {schedulesLoading ? (
                <LoadingSpinnerComponent />
              ) : (
                <TableContainer style={{ width: "100%" }}>
                  <Table>
                    <TableHead>
                      <TableRow key={"columnHead"}>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:id")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:fullName")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:department")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:position")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:shift")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:startDate")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:endDate")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:preview")}
                        </TableCell>
                        <TableCell align={isEnglish ? "left" : "right"}>
                          {t("schedules:actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{tableBody}</TableBody>
                  </Table>
                  {schedules.length === 0 && (
                    <div style={{ textAlign: "center", padding: 20 }}>
                      <Typography
                        style={{ fontSize: "20px", fontStyle: "normal" }}
                      >
                        {t("schedules:noRecords")}
                      </Typography>
                    </div>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    rowsPerPage={rowsPerPage}
                    count={schedules.length}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              )}
            </Box>
          </Grid>
          {/* Grid at the bottom */}
          <Grid
            container
            justifyContent="flex-end"
            item
            xs={12}
            style={{
              width: "100%",
            }}
          >
            {/* <Link to="/assign-schedules">
              <Button
                variant="contained"
                className={classes.addButton}
                endIcon={<ArrowRight className={classes.addIcon} />}
              >
                {t("schedules:assignSchedules")}
              </Button>
            </Link> */}
          </Grid>
        </Box>

        {matchesLg && <ScheduleDetailsTable isEnglish={isEnglish} t={t} />}
      </Grid>

      {id || handleSchedulesChange ? (
        <CreateSchedule
          open={open}
          setOpen={setOpen}
          shifts={shifts}
          handleSchedulesChange={handleSchedulesChange}
          createOrUpdateEmployee={true}
          id={id ? id : null}
        />
      ) : (
        <CreateSchedule open={open} setOpen={setOpen} shifts={shifts} />
      )}
    </>
  );
};
