// Graphql
import client from "./client";
//Mutations
import {
  CREATE_TIME_TABLE,
  UPDATE_TIME_TABLE,
  DELETE_TIME_TABLE,
  CREATE_SHIFT,
  UPDATE_SHIFT,
  DELETE_SHIFT,
  CREATE_SCHEDULE,
  DELETE_SCHEDULE,
} from "./Mutations";
//Queries
import {
  TIMETABLES_QUERY,
  SHIFTS_QUERY,
  SCHEDULES_QUERY,
  SCHEDULE_DETAILS_QUERY,
} from "./Queries";
// Action Types
import {
  TIME_TABLES,
  SHIFTS,
  SCHEDULES,
  SCHEDULE_DETAILS,
  GET_ERRORS,
  ADD_TIME_TABLE,
  EDIT_TIME_TABLE,
  REMOVE_TIME_TABLE,
  ADD_SHIFT,
  EDIT_SHIFT,
  REMOVE_SHIFT,
  ADD_SCHEDULE,
  REMOVE_SCHEDULE,
  TIME_TABLES_LOADING,
  SHIFTS_LOADING,
  SCHEDULES_LOADING,
  SCHEDULE_DETAILS_LOADING,
} from "./types";

export const fetchTimeTables = () => async (dispatch) => {
  try {
    dispatch({ type: TIME_TABLES_LOADING });
    const res = await client.query({
      query: TIMETABLES_QUERY,
      variables: null,
    });

    const timeTables = res.data.timeTables;
    dispatch({ type: TIME_TABLES, payload: timeTables });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchShifts = () => async (dispatch) => {
  try {
    dispatch({ type: SHIFTS_LOADING });
    const res = await client.query({
      query: SHIFTS_QUERY,
      variables: null,
    });

    const shifts = res.data.shifts;
    dispatch({ type: SHIFTS, payload: shifts });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchSchedules = () => async (dispatch) => {
  try {
    dispatch({ type: SCHEDULES_LOADING });
    const res = await client.query({
      query: SCHEDULES_QUERY,
      variables: null,
    });

    const schedules = res.data.employeesSchedules;
    dispatch({ type: SCHEDULES, payload: schedules });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchScheduleDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: SCHEDULE_DETAILS_LOADING });
    const res = await client.query({
      query: SCHEDULE_DETAILS_QUERY,
      variables: id,
    });

    const scheduleDetails = res.data.scheduleDetails;
    dispatch({ type: SCHEDULE_DETAILS, payload: scheduleDetails });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createTimeTable = (timeTable) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: CREATE_TIME_TABLE,
      variables: timeTable,
    });

    const newTimeTable = res.data.createTimeTable.timetable;
    dispatch({ type: ADD_TIME_TABLE, payload: newTimeTable });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateTimeTable = (data) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: UPDATE_TIME_TABLE,
      variables: data,
    });

    const updatedTimeTable = res.data.updateTimeTable.timetable;
    dispatch({ type: EDIT_TIME_TABLE, payload: updatedTimeTable });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const deleteTimeTable = (id) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: DELETE_TIME_TABLE,
      variables: id,
    });
    if (res.data.deleteTimeTable.status) {
      dispatch({ type: REMOVE_TIME_TABLE, payload: id.id });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createShift = (shift) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: CREATE_SHIFT,
      variables: shift,
    });

    const newShift = res.data.createShift.shift;
    dispatch({ type: ADD_SHIFT, payload: newShift });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateShift = (data) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: UPDATE_SHIFT,
      variables: data,
    });

    const updatedShift = res.data.updateShift.shift;
    dispatch({ type: EDIT_SHIFT, payload: updatedShift });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const deleteShift = (id) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: DELETE_SHIFT,
      variables: id,
    });
    if (res.data.deleteShift.status) {
      dispatch({ type: REMOVE_SHIFT, payload: id.id });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createSchedule = (schedules) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: CREATE_SCHEDULE,
      variables: schedules,
    });

    const newSchedules = res.data.createEmployeeSchedule.schedules;
    console.log("newSchedules", newSchedules);
    dispatch({ type: ADD_SCHEDULE, payload: newSchedules });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const deleteSchedule = (id) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: DELETE_SCHEDULE,
      variables: id,
    });
    console.log("deleteSchedule id", id);
    if (res.data.deleteEmployeeSchedule.status) {
      dispatch({ type: REMOVE_SCHEDULE, payload: id.id });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};
