import {Box, Typography} from '@material-ui/core'

import {NotesSharp} from '@material-ui/icons'
import React from 'react'

const CenterLabel = ({title}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      width={'100%'}
      height={50}
      alignItems="center"
      justifyContent="flex-start"
    >
      <NotesSharp color="primary" />
      <Typography variant="h3" color="primary">
        {title}
      </Typography>
    </Box>
  )
}

export default CenterLabel
