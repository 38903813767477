import { Box, TableCell, TableRow } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import UpdateOrDeleteTimeTable from "./Modals/updateOrDeleteTimeTable.js";

export const TimeTableItem = ({ timeTable }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["schedules"]);
  const isEnglish = language === "en";
  const [openEdit, setEdit] = useState(false);
  const [openDelete, setDelete] = useState(false);

  // const handleEdit = () => {
  //   console.log("Edit");
  // };
  // const handleDelete = () => {
  //   console.log("Delete");
  // };
  return (
    <>
      <Box clone whiteSpace="nowrap">
        <TableRow key={timeTable.id}>
          <TableCell align={isEnglish ? "left" : "right"}>
            {timeTable.name}
          </TableCell>
          <TableCell
            align={isEnglish ? "left" : "right"}
            style={{
              whiteSpace: "nowrap",
              borderBottom: "none",
            }}
          >
            {timeTable.checkIn}
          </TableCell>
          <TableCell
            align={isEnglish ? "left" : "right"}
            style={{
              whiteSpace: "nowrap",
              borderBottom: "none",
            }}
          >
            {timeTable.checkOut}
          </TableCell>
          <TableCell align={isEnglish ? "left" : "right"}>
            {timeTable.duration}
          </TableCell>
          <TableCell align={isEnglish ? "left" : "right"}>
            <Edit
              onClick={() => {
                setEdit(true);
              }}
              color="primary"
              style={{ cursor: "pointer" }}
            />{" "}
            <Delete
              onClick={() => {
                setDelete(true);
              }}
              color="error"
              style={{ cursor: "pointer" }}
            />
          </TableCell>
        </TableRow>
      </Box>
      <UpdateOrDeleteTimeTable
        openEdit={openEdit}
        setEdit={setEdit}
        openDelete={openDelete}
        setDelete={setDelete}
        t={t}
        timeTable={timeTable}
      />
    </>
  );
};
