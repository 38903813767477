import React from 'react'
//Material UI
import {TableCell} from '@material-ui/core'
import theme from '../../styles/theme'

const PositionTableCell = ({colSpan, children}) => {
  return (
    <TableCell
      style={{
        paddingBottom: 0,
        paddingTop: 0,
        borderBottom: 'none',
        backgroundColor: theme.palette.common.aliceBlue,
      }}
      colSpan={colSpan}
    >
      {children}
    </TableCell>
  )
}

export default PositionTableCell
