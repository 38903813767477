import React from "react";

import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PageHeader } from "../../../redesign/components/common";
import { paths } from "../../../redesign/utils";
import { ShiftsComponent } from "./ShiftsComponent";

const Shifts = () => {
  const { t } = useTranslation(["breadcrumbs", "common"]);
  let navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.attendances.name}`),
      link: paths.attendances.link,
    },
    {
      name: t(`breadcrumbs:${paths.attendances.shifts.name}`),
      isLast: true,
    },
  ];
  return (
    <Box margin={2}>
      <PageHeader
        title={t(`breadcrumbs:${paths.attendances.shifts.name}`)}
        paths={navigationPaths}
        paddingBottom={5}
      />
      <ShiftsComponent />
    </Box>
  );
};

export default Shifts;
