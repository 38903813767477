import { Button, Grid, Typography } from "@material-ui/core";
import { Cancel, Delete } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { deleteSchedule } from "../../../../redux/actions";
import { CompanyStructureModal } from "../../../common";
import useStyles from "../styles";

const DeleteSchedule = ({ openDelete, setDelete, t, schedule }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      deleteSchedule({ id: schedule.id, employeeId: schedule.employee.id }),
    );
    setDelete(false);
  };
  return (
    <CompanyStructureModal
      open={openDelete}
      onClose={() => setDelete(false)}
      fadeIn={openDelete}
      title={t("deleteschedule")}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        style={{ marginTop: -30, padding: "30px" }}
      >
        <Grid item xs={12}>
          <Typography>
            {`${t("schedules:deleteScheduleMessage")} ${
              schedule.employee.fullName
            }:${schedule.startDate}-${schedule.endDate} ?`}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          item
          xs={12}
        >
          <Button
            variant="contained"
            className={classes.cancelButton}
            endIcon={<Delete className={classes.addIcon} />}
            onClick={() => {
              handleDelete();
            }}
          >
            {t("schedules:delete")}
          </Button>

          <Button
            variant="contained"
            className={classes.addButton}
            endIcon={<Cancel className={classes.addIcon} />}
            onClick={() => {
              setDelete(false);
            }}
          >
            {t("schedules:cancel")}
          </Button>
        </Grid>
      </Grid>
    </CompanyStructureModal>
  );
};

export default DeleteSchedule;
