import { Badge, Box, Button, Collapse, ListItem } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { styled } from "@material-ui/styles"; // Import styled from @material-ui/styles
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";

const SidebarMenuItem = ({
  children,
  link,
  icon: Icon,
  badge,
  name,
  ...rest
}) => {
  const { pathname } = useLocation();

  let active = pathname === link && name !== "shifts";

  const [menuToggle, setMenuToggle] = useState(false);
  const {
    t,
    i18n: { language },
  } = useTranslation("navLinks");
  const isEnglish = language === "en";

  const toggleMenu = () => {
    setMenuToggle((open) => !open);
  };

  if (children) {
    return (
      <>
        <StyledListItem key={name} {...rest}>
          <StyledButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            active={active.toString()}
            startIcon={
              Icon && (
                <Icon
                  style={{
                    color: active ? "#002553" : "inherit",
                    opacity: active ? 0.4 : 1,
                  }}
                />
              )
            }
            endIcon={
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type=""
                onClick={toggleMenu}
              >
                {menuToggle ? (
                  <ExpandLess color="primary" />
                ) : (
                  <ExpandMore color="primary" />
                )}
              </span>
            }
          >
            {badge && <Badge badgeContent={badge} />}
            <Box
              component={RouterLink}
              to={link ? link : ""}
              style={{
                color: active ? "#002553" : "inherit",
                textDecoration: "none",
                textTransform: "none",
                marginRight: !isEnglish && 8,
                whiteSpace: "nowrap",
              }}
            >
              {t(name)}
            </Box>
          </StyledButton>
        </StyledListItem>
        <Collapse in={menuToggle} style={{ paddingLeft: 30 }}>
          {children}
        </Collapse>
      </>
    );
  }

  let to = link;

  let depositsTo = {
    pathname: "/deposits",
    state: {
      prevPath: window.location.pathname,
      search: window.location.search,
      from: "/employeesList",
    },
  };

  return (
    <Box
      component={RouterLink}
      to={link === "/deposits" ? depositsTo : to}
      style={{ textDecoration: "none", textTransform: "none" }}
    >
      <StyledListItem key={name} {...rest}>
        <StyledButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          active={active.toString()}
          startIcon={
            Icon && (
              <Icon
                style={{
                  opacity: active ? 0.4 : 1,
                  color: active ? "#002553" : "inherit",
                }}
              />
            )
          }
        >
          <Box
            style={{
              marginRight: !isEnglish && 8,
              marginLeft: isEnglish && 2,
              whiteSpace: "nowrap",
            }}
          >
            {t(name)}
          </Box>

          {badge && <Badge badgeContent={badge} />}
        </StyledButton>
      </StyledListItem>
    </Box>
  );
};

export default SidebarMenuItem;

const StyledListItem = styled(ListItem)({
  color: "#002553",
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const StyledButton = styled(Button)(({ active }) => ({
  color: active === "true" ? "#002553" : "inherit",
  opacity: active === "true" ? 0.4 : 1,
  textTransform: "none",
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
