import { Box, TableCell, TableRow } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { UpdateShift } from "./Modals/UpdateShiftModal";
import DeleteShift from "./Modals/deleteShiftModal";

export const ShiftItem = ({
  shift,
  setSelected,
  selected,
  week,
  timeTables,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["schedules"]);
  const isEnglish = language === "en";

  const isSelected = selected?.id === shift.id;
  const [openEdit, setEdit] = useState(false);
  const [openDelete, setDelete] = useState(false);
  const classes = useStyles();
  console.log("shift item", shift);
  const handleClick = () => {
    setSelected(shift);
  };
  // const handleEdit = () => {
  //   console.log("Edit");
  // };
  // const handleDelete = () => {
  //   console.log("Delete");
  // };
  return (
    <>
      <Box clone whiteSpace="nowrap">
        <TableRow
          key={shift.id}
          onClick={handleClick}
          className={`${classes.tableRow} ${
            isSelected ? classes.selected : ""
          }`}
        >
          <TableCell align={isEnglish ? "left" : "right"}>
            {shift.name}
          </TableCell>
          <TableCell
            align={isEnglish ? "left" : "right"}
            style={{
              whiteSpace: "nowrap",
              borderBottom: "none",
            }}
          >
            {shift.timetables.map((timeTable) => timeTable.name).join(", ")}
          </TableCell>
          <TableCell
            align={isEnglish ? "left" : "right"}
            style={{
              whiteSpace: "nowrap",
              borderBottom: "none",
            }}
          >
            {shift.unit}
          </TableCell>
          <TableCell align={isEnglish ? "left" : "right"}>
            {shift.cycle}
          </TableCell>
          <TableCell align={isEnglish ? "left" : "right"}>
            <Edit
              onClick={() => {
                setEdit(true);
              }}
              color="primary"
              style={{ cursor: "pointer" }}
            />{" "}
            <Delete
              onClick={() => {
                setDelete(true);
              }}
              color="error"
              style={{ cursor: "pointer" }}
            />
          </TableCell>
        </TableRow>
      </Box>
      <UpdateShift
        open={openEdit}
        setOpen={setEdit}
        week={week}
        timeTables={timeTables}
        selectedShift={shift}
      />
      <DeleteShift
        openDelete={openDelete}
        setDelete={setDelete}
        t={t}
        shift={shift}
      />
    </>
  );
};
