import { Button, Grid } from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import React, { useState } from "react";
import TimePickerField from "../../../common/TimePickerField";
import { CompanyStructureModal, FormInputField } from "../../../common";
import useStyles from "../styles";
import moment from "moment";
import { useDispatch } from "react-redux";
import { createTimeTable } from "../../../../redux/actions";

export const CreateTimeTable = ({ open, setOpen, t }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [timeTable, setTimeTable] = useState({
    name: "",
    checkIn: null,
    checkOut: null,
  });
  const [error, setError] = useState(false);
  const handleSubmit = () => {
    let errors = {};
    if (timeTable.name === "") {
      errors = { ...errors, name: t("schedules:nameRequired") };
    }
    if (timeTable.checkIn === null) {
      errors = { ...errors, checkIn: t("schedules:checkInRequired") };
    }
    if (timeTable.checkOut === null) {
      errors = { ...errors, checkOut: t("schedules:checkOutRequired") };
    }
    setError(errors);
    console.log("errors", errors);
    console.log("error", error);
    console.log("timeTable", timeTable);
    if (
      timeTable.name !== "" &&
      timeTable.checkIn !== null &&
      timeTable.checkOut !== null
    ) {
      setError(false);
      dispatch(createTimeTable(timeTable));
      setOpen(false);
      setTimeTable({
        name: "",
        checkIn: null,
        checkOut: null,
      });
    }
  };
  const handleClose = () => {
    setError(false);
    setOpen(false);
    setTimeTable({
      name: "",
      checkIn: null,
      checkOut: null,
    });
  };
  return (
    <CompanyStructureModal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fadeIn={open}
      title={t("schedules:createTimeTable")}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        style={{ marginTop: -30, padding: "15px" }}
      >
        <Grid item xs={12}>
          <FormInputField
            label={t("schedules:name")}
            errors={error.name}
            placeholder={t("schedules:name")}
            type="text"
            required
            value={timeTable.name}
            onChange={(e) => {
              setTimeTable({ ...timeTable, name: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TimePickerField
            label={t("schedules:checkIn")}
            errors={error.checkIn}
            value={
              timeTable.checkIn ? moment(timeTable.checkIn, "HH:mm:ss") : null
            }
            onChange={(e) => {
              setTimeTable({
                ...timeTable,
                checkIn: moment(e).set("s", 0).format("HH:mm:ss"),
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TimePickerField
            label={t("schedules:checkOut")}
            errors={error.checkOut}
            value={
              timeTable.checkOut ? moment(timeTable.checkOut, "HH:mm:ss") : null
            }
            onChange={(e) => {
              setTimeTable({
                ...timeTable,
                checkOut: moment(e).set("s", 0).format("HH:mm:ss"),
              });
            }}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          item
          xs={12}
        >
          <Button
            variant="contained"
            className={classes.saveButton}
            endIcon={<Save className={classes.addIcon} />}
            onClick={() => {
              handleSubmit();
            }}
          >
            {t("schedules:save")}
          </Button>

          <Button
            variant="contained"
            className={classes.cancelButton}
            endIcon={<Cancel className={classes.addIcon} />}
            onClick={() => {
              handleClose();
            }}
          >
            {t("schedules:cancel")}
          </Button>
        </Grid>
      </Grid>
    </CompanyStructureModal>
  );
};
