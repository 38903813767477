import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  Table,
} from "@material-ui/core";
import { CancelOutlined, Save } from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyStructureModal, DatePickerField } from "../../../common";
import useStyles from "../styles";
import { Alert } from "@material-ui/lab";
import { createSchedule } from "../../../../redux/actions";
import { useDispatch } from "react-redux";

import { SelectEmployeesTable } from "./selectEmployeesTable";

export const CreateSchedule = ({
  open,
  setOpen,
  shifts,
  createOrUpdateEmployee = false,
  handleSchedulesChange,
  id,
}) => {
  const {
    t,
    // i18n: { language },
  } = useTranslation(["schedules"]);
  // const isEnglish = language === "en";

  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [overwriteSchedule, setOverwriteSchedule] = useState(false);
  const [error, setError] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("M").format("DD-MM-YYYY"),
    endDate: moment().endOf("M").format("DD-MM-YYYY"),
  });

  const handleShiftSelection = (shift) => {
    if (selectedShift?.id === shift.id) {
      setSelectedShift(null);
    } else {
      setSelectedShift(shift);
    }
  };

  const handleDateChange = (field, e) => {
    setDateRange({ ...dateRange, [field]: moment(e).format("DD-MM-YYYY") });
  };

  const handleOverwriteSchedule = (e) => {
    setOverwriteSchedule(e.target.checked);
  };

  const handleSubmit = () => {
    console.log("selectedShift", selectedShift);
    console.log("dateRange", dateRange);
    console.log("overwriteSchedule", overwriteSchedule);
    let errors = {};
    if (!selectedEmployee && !createOrUpdateEmployee) {
      let empError = t("schedules:selectEmployeeError");
      errors = { ...errors, employee: empError };
    }
    if (!selectedShift) {
      let shiftError = t("schedules:selectShiftError");
      errors = { ...errors, shift: shiftError };
    }
    setError(errors);
    if (error) {
      console.log("error", error);
      return;
    }
    if (createOrUpdateEmployee) {
      handleSchedulesChange({
        shiftId: selectedShift.id,
        startDate: moment(dateRange.startDate, "DD-MM-YYYY").format(
          "YYYY-MM-DD",
        ),
        endDate: moment(dateRange.endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        overwriteSchedule: false,
      });
    } else {
      let schedules = selectedEmployee.map((employee) => {
        return {
          employeeId: employee.id,
          shiftId: selectedShift.id,
          startDate: moment(dateRange.startDate, "DD-MM-YYYY").format(
            "YYYY-MM-DD",
          ),
          endDate: moment(dateRange.endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          overwriteSchedule,
        };
      });
      console.log("schedules", schedules);
      dispatch(createSchedule({ schedulesInput: schedules }));
    }
    setOpen(false);
    setError(false);
    setSelectedEmployee(null);
    setSelectedShift(null);
  };
  const handleClose = () => {
    setOpen(false);
    setError(false);
    setSelectedEmployee(null);
    setSelectedShift(null);
  };
  return (
    <CompanyStructureModal
      open={open}
      onClose={() => handleClose()}
      fadeIn={open}
      title={t("schedules:assignSchedule")}
      width="80%"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 15,
          boxShadow: "inset 0px 0px 20px #ccc",
          borderRadius: 10,
        }}
      >
        {error && (error?.employee || error?.shift) && (
          <div style={{ width: "100%", margin: "3px", padding: "5px" }}>
            {" "}
            <Alert severity="error">
              {`${error?.employee ? error.employee : ""} ${
                error?.shift ? error.shift : ""
              }`}{" "}
            </Alert>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {!createOrUpdateEmployee && (
            <div
              style={{
                width: "40%",
                maxWidth: "40%",
                height: "50vh",
                marginInline: 5,
              }}
            >
              <SelectEmployeesTable
                setSelectedEmployee={setSelectedEmployee}
                selectedEmployee={selectedEmployee}
              />
            </div>
          )}
          <div
            style={{
              width: `${createOrUpdateEmployee ? "100%" : "60%"}`,
              maxWidth: `${createOrUpdateEmployee ? "100%" : "60%"}`,
              height: "50vh",
              marginInline: 5,
            }}
          >
            <div
              style={{
                borderRight: "1px solid grey",
                borderTop: "1px solid grey",
                borderLeft: "1px solid grey",
                backgroundColor: "#e6ebf1",
                borderBottom: "2px solid #ccc",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  padding: 5,
                  height: 75,
                }}
              >
                <div style={{ width: "50%" }}>
                  <DatePickerField
                    label={t("schedules:startDate")}
                    name={"startDate"}
                    isRequired={true}
                    value={moment(dateRange.startDate, "DD-MM-YYYY")}
                    onChange={(e) => handleDateChange("startDate", e)}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <DatePickerField
                    label={t("schedules:endDate")}
                    name={"endDate"}
                    isRequired={true}
                    min={moment(dateRange.startDate, "DD-MM-YYYY").add(1, "d")}
                    value={moment(dateRange.endDate, "DD-MM-YYYY")}
                    onChange={(e) => handleDateChange("endDate", e)}
                  />
                </div>
              </div>
              <div style={{ height: 75 }}>
                <FormControlLabel
                  label={t("schedules:overwriteSchedule")}
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={overwriteSchedule}
                      onChange={(e) => handleOverwriteSchedule(e)}
                      disabled={createOrUpdateEmployee && !id}
                    />
                  }
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                maxHeight: "calc(50vh - 75px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                overflowY: "auto",
                borderRight: "1px solid grey",
                borderBottom: "1px solid grey",
                borderLeft: "1px solid grey",
              }}
            >
              <div>
                <Table>
                  <thead>
                    <tr key={"ShiftsTableHead"} className={classes.tableRow}>
                      <th
                        className={`${classes.tableHead} ${classes.fixedHeader} ${classes.firstColumn}`}
                      >
                        <div
                          style={{
                            boxShadow: "5px 0px 9px #ddd",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                      </th>

                      <th
                        align="center"
                        className={classes.tableHead}
                        style={{
                          borderInlineEnd: "1px solid grey",
                          minWidth: 150,
                        }}
                      >
                        {t("schedules:name")}
                      </th>
                      <th
                        align="center"
                        className={classes.tableHead}
                        style={{
                          borderInlineEnd: "1px solid grey",
                          minWidth: 150,
                        }}
                      >
                        {t("schedules:timeTable")}
                      </th>
                      <th
                        align="center"
                        className={classes.tableHead}
                        style={{
                          borderInlineEnd: "1px solid grey",
                          minWidth: 150,
                        }}
                      >
                        {t("schedules:unit")}
                      </th>
                      <th
                        align="center"
                        className={classes.tableHead}
                        style={{
                          borderInlineEnd: "hidden",
                          minWidth: 150,
                        }}
                      >
                        {t("schedules:cycle")}
                      </th>
                    </tr>
                  </thead>

                  <tbody className={classes.tableBody}>
                    {shifts.map((shift, index) => (
                      <tr
                        className={`${classes.tableRow} ${
                          selectedShift?.id === shift.id ? classes.selected : ""
                        }`}
                        style={{ height: 50 }}
                        onClick={() => handleShiftSelection(shift)}
                        key={index + "rows"}
                      >
                        <td
                          className={`${classes.firstColumn} ${classes.tableCell}`}
                          align="center"
                        >
                          <div
                            style={{
                              display: "flex",
                              boxShadow: "5px 0px 9px #ddd",
                              height: "100%",
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Radio checked={shift.id === selectedShift?.id} />
                          </div>
                        </td>
                        <td align="center" className={classes.tableCellBorders}>
                          {shift.name}
                        </td>
                        <td align="center" className={classes.tableCellBorders}>
                          {shift.timetables
                            .map((timeTable) => timeTable.name)
                            .join(", ")}
                        </td>
                        <td align="center" className={classes.tableCellBorders}>
                          {shift.unit}
                        </td>
                        <td align="center" className={classes.tableCellBorders}>
                          {shift.cycle}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={handleSubmit}
              endIcon={<Save />}
            >
              {t("schedules:save")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleClose()}
              style={{ marginInline: 30 }}
              className={classes.cancelButton}
              endIcon={<CancelOutlined />}
            >
              {t("schedules:cancel")}
            </Button>
          </div>
        </div>
      </div>
    </CompanyStructureModal>
  );
};
