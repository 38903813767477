//Material UI
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@material-ui/core'

import CustomTableCell from './CustomTableCell'
//Components
import CustomTableRow from './CustomTableRow'
import React from 'react'
//Translation
import {useTranslation} from 'react-i18next'

const VacationRequest = ({employeeData}) => {
  const {t} = useTranslation('common')

  const columns = [
    t('common:description'),
    t('common:startDate'),
    t('common:endDate'),
    t('common:status'),
  ]

  const headerRow = columns.map((column, i) => (
    <CustomTableCell key={(column, +i)}>
      <Typography variant="subtitle2" color="textPrimary">
        {column}
      </Typography>
    </CustomTableCell>
  ))

  const data = employeeData

  const tableRow = data.map((employee, i) => (
    <CustomTableRow key={(employee, +i)}>
      <CustomTableCell>
        <Typography variant="subtitle2" color="textSecondary">
          {employee.description}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="subtitle2" color="textSecondary">
          {employee.startDate}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="subtitle2" color="textSecondary">
          {employee.endDate}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="subtitle2" color="textSecondary">
          {employee.status}
        </Typography>
      </CustomTableCell>
    </CustomTableRow>
  ))

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <Box clone bgcolor="inherit">
            <CustomTableRow>{headerRow}</CustomTableRow>
          </Box>
        </TableHead>

        <TableBody>{tableRow}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default VacationRequest
