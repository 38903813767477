import React, { useEffect, useState } from "react";
import { FormSelectInput } from "../../../common";
import { Checkbox, Table } from "@material-ui/core";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const SelectEmployeesTable = ({
  selectedEmployee,
  setSelectedEmployee,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["schedules"]);
  const isEnglish = language === "en";
  const classes = useStyles();
  console.log("selectedEmployee&&&", selectedEmployee);
  const departments = useSelector((state) => state.company.departments);
  const employees = useSelector((state) => state.employees.employees);

  const [selectedDepartment, setSelectedDepartment] = useState("ALL");
  const [departmentsList, setDepartmentsList] = useState([
    { label: isEnglish ? "All" : "الكل", value: "ALL" },
    ...departments.map((department) => {
      return {
        label: isEnglish ? department.name : department.nameAr,
        value: department.id,
      };
    }),
  ]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  useEffect(() => {
    if (employees) {
      const sortedEmployees = [...employees]
        .filter((employee) =>
          selectedDepartment === "ALL"
            ? true
            : employee.department.id === selectedDepartment,
        )
        .sort((a, b) => {
          const employeeA = a.referenceNumber ? a.referenceNumber : a.id;
          const employeeB = b.referenceNumber ? b.referenceNumber : b.id;
          return employeeA - employeeB;
        });
      setFilteredEmployees(sortedEmployees);
    }
  }, [employees, selectedDepartment]);

  const handleEmployeeSelection = (employee) => {
    if (!selectedEmployee) {
      setSelectedEmployee([employee]);
    } else {
      let existingEmployee = selectedEmployee.find(
        (emp) => emp.id === employee.id,
      );
      if (existingEmployee) {
        setSelectedEmployee(
          selectedEmployee.filter((emp) => emp.id !== employee.id),
        );
      } else {
        setSelectedEmployee([...selectedEmployee, employee]);
      }
    }
  };

  const handleSelectAllEmployees = () => {
    if (selectedEmployee.length === employees.length) {
      setSelectedEmployee([]);
    } else {
      setSelectedEmployee(employees);
    }
  };

  const isSelectedEmployee = (employee) => {
    return selectedEmployee?.find((emp) => emp.id === employee.id)
      ? true
      : false;
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "#e6ebf1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRight: "1px solid grey",
          borderTop: "1px solid grey",
          borderLeft: "1px solid grey",
          borderBottom: "2px solid #ccc",
        }}
      >
        <div style={{ height: 75 }}>
          <h2>{t("schedules:selectEmployees")}</h2>
        </div>
        <div style={{ height: 75, width: "100%", padding: 5 }}>
          <FormSelectInput
            label={t("department")}
            selectOptions={departmentsList.map((department) => {
              return department;
            })}
            onChange={(departmrnt) => {
              console.log("departmrnt", departmrnt);
              setSelectedDepartment(departmrnt);
              setSelectedEmployee([]);
            }}
            value={selectedDepartment ? selectedDepartment : null}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: "calc(50vh - 75px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          overflowY: "auto",
          borderRight: "1px solid grey",
          borderBottom: "1px solid grey",
          borderLeft: "1px solid grey",
        }}
      >
        <Table>
          <thead>
            <tr key={"SchedulesTableHead"} className={classes.tableRow}>
              <th
                align="center"
                className={`${classes.tableHead} ${classes.fixedHeader} ${classes.firstColumn}`}
              >
                <div
                  style={{
                    boxShadow: "5px 0px 9px #ddd",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Checkbox
                    checked={selectedEmployee.length === employees.length}
                    onClick={handleSelectAllEmployees}
                  />
                </div>
              </th>
              <th
                align="center"
                className={classes.tableHead}
                style={{ borderInlineEnd: "1px solid grey" }}
              >
                {t("schedules:id")}
              </th>
              <th
                align="center"
                className={classes.tableHead}
                key={"timeTablecell day"}
              >
                {t("schedules:fullName")}
              </th>
            </tr>
          </thead>

          <tbody className={classes.tableBody}>
            {filteredEmployees.map((employee) => (
              <tr
                key={employee.id}
                onClick={() => handleEmployeeSelection(employee)}
                className={`${classes.tableRow} ${
                  selectedEmployee?.find((emp) => emp.id === employee.id)
                    ? classes.selected
                    : ""
                }`}
                style={{ height: 50 }}
              >
                <td
                  align="center"
                  className={`${classes.firstColumn} ${classes.tableCell}`}
                >
                  <div
                    style={{
                      display: "flex",
                      boxShadow: "5px 0px 9px #ddd",
                      height: "100%",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox checked={isSelectedEmployee(employee)} />
                  </div>
                </td>
                <td
                  align="center"
                  className={classes.tableCellBorders}
                  style={{
                    width: 100,
                  }}
                >
                  {employee.referenceNumber
                    ? employee.referenceNumber
                    : employee.id}
                </td>
                <td
                  align="center"
                  className={classes.tableCellBorders}
                  style={{
                    minWidth: 50,
                  }}
                >
                  {employee.fullName}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};
